import React from "react"
import Navbar from "../Navbar";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AlertList from "../Alert/Alert";
import Form from 'react-bootstrap/Form';
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import { useAuthHeader } from "react-auth-kit";
import axios from "axios";
import { useRef } from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';
import jwt_decode from 'jwt-decode';

function NewServiceAndRemote(){
    const [show, setShow] = React.useState(false);
    const [modalHeading, setModalHeading] = React.useState("");
    const [modalBody, setModalBody] = React.useState("");
    const [showSubmit, setShowSubmit] = React.useState(true);
    const [validated, setValidated] = React.useState(false);
    const [isMobile, setIsMobile] = React.useState(false);
    const [beam, setBeam] = React.useState('Single');
    const [lnbManufacturer, setLnbManufacturer] = React.useState([]);
    const [bucManufacturer, setBucManufacturer] = React.useState([]);
    const [customers, setCustomers] = React.useState([]);
    const [selectedLnb, setSelectedLnb] = React.useState('');
    const [selectedBuc, setSelectedBuc] = React.useState('');
    const [selectedBucModel, setSelectedBucModel] = React.useState('');
    const [selectedSubnet, setSelectedSubnet] = React.useState('');
    const [selectedSrv, setSelectedSrv] = React.useState('');
    const [lat, setLat] = React.useState(0);
    const [long, setLong] = React.useState(0);
    const [lnb, setLnb] = React.useState([]);
    const [buc, setBuc] = React.useState([]);
    const [sat, setSat] = React.useState([]);
    const [reflectors, setReflectors] = React.useState([]);
    const [availableReflectors, setAvailableReflectors] = React.useState([]);
    const [isNMEA, setIsNMEA] = React.useState(false);
    const [alerts, setAlerts] = React.useState([]);
    const [task, setTask] = React.useState('');
    const [fail, setFail] = React.useState(0);

    const formRef = useRef(null);

    const authHeader = useAuthHeader();
    const token = authHeader().replace('Bearer ','')

    const is_superuser = jwt_decode(token).is_superuser

    const handleClose = () => {
        setShow(false);
        setShowSubmit(true);
    };

    React.useEffect(() => {
        const fetchTask = async (task) => {
            let url = `api/bcgtasks/${task}`;
            const options = {
            method: "GET",
            headers: {
                "content-type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
                Authorization: authHeader(),
            },
            url: url,
            };
            await axios(options)
            .then((resp) => {
                const result = resp.data;
                console.log(result)
                if ((result.task_status === "FAILURE" && fail >= 5) || result.task_status === "SUCCESS") {
                    setTask(state => (''))
                }
                else if (result.task_status === "FAILURE") setFail((prevState) => prevState + 1)
                const statusTask = result.task_status.toLowerCase()
                setModalHeading(`Status: ${statusTask[0].toUpperCase() + statusTask.slice(1)}`)
                const curState = result.task_result?.current 
                const totalState = result.task_result?.total
                setModalBody((
                    <>
                    {result.task_result?.message}
                    {(curState && totalState) && <ProgressBar now={Number((curState/totalState)*100)} />}
                    </>
                ))
            })
            .catch((error) => {
                setTask(state => ('')) 
                console.error(`Error: ${error}`);
                setModalHeading('Status: Internal error')
                setModalBody('Something went wrong, please contact NOC')    
            });
        };

        const interval = setInterval(() => {
            if (task.length > 0){
                fetchTask(task)
            }
        }, 1000);
    
        return () => clearInterval(interval);
      }, [task]);

      
    React.useEffect(() => {

        const fetchCustomers = async ()=>{
            let url = 'api/customer/';
            const options = {
                method: "GET",
                headers: {
                    "accept": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: authHeader(),
                },
                url: url,
            };
            await axios(options)
            .then((resp) => {
                let result = resp.data?.filter(e=> e.reseller);
                
                setCustomers([...result])
            })
        }

        const fetchFormData = async ()=>{
            let url = `api/remotes/form`;
            const options = {
            method: "GET",
            headers: {
                "content-type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
                Authorization: authHeader(),
            },
            url: url,
            };
            await axios(options)
            .then((resp) => {
                let result = resp.data;
                result.satellites.sort(function(a, b) {
                    if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                    if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                    return 0;
                })
                setSat([...result.satellites])
                setLnb([...result.lnb])
                setBuc([...result.buc])
                setReflectors([...result.reflector])
                let lnbManuf = []
                result.lnb.forEach((lnb) => {
                    if (lnb.manufacturer !== '') {
                        lnbManuf.push(lnb.manufacturer)
                    }
                })
                lnbManuf = Array.from(new Set(lnbManuf));
                lnbManuf.sort(function(a, b) {
                    if(a.toLowerCase() < b.toLowerCase()) return -1;
                    if(a.toLowerCase() > b.toLowerCase()) return 1;
                    return 0;
                })
                setLnbManufacturer([...lnbManuf])
                let bucManuf = []
                result.buc.forEach((buc) => {
                    if (buc.manufacturer !== '') {
                        bucManuf.push(buc.manufacturer)
                    }
                })
                bucManuf = Array.from(new Set(bucManuf));
                bucManuf.sort(function(a, b) {
                    if(a.toLowerCase() < b.toLowerCase()) return -1;
                    if(a.toLowerCase() > b.toLowerCase()) return 1;
                    return 0;
                })
                setBucManufacturer([...bucManuf])
            })
            .catch((error) => {
                console.error(`Error: ${error.message}`);
                setAlerts([
                    ...alerts, 
                    {
                        severity:"danger",
                        message:"Something went wrong."
                    }
                ])
                
            });
        };
        fetchFormData();
        is_superuser&&fetchCustomers()
    }, []);

    const postRemote = async (data) => {
        let url = `api/service/form-post`;
        const options = {
            method: "POST",
            headers: {
                "content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: authHeader(),
            },
            url: url,
            data: data
        };
        await axios(options)
        .then((resp) => {
            const result = resp.data
            console.log(`Task id:${result}`)
            setTask(result)
            setModalHeading('Task progress')
            setModalBody('Task created, please wait...')
        })
        .catch((error) => {
            let alert_data = {
                severity:"danger",
                message:"Something went wrong."
            }
            console.error(`Error: ${error.message}`);
            if (error.response.status === 400) {
                alert_data.message = error.response.data.detail
            }
            setAlerts([alert_data])
            setModalBody('Something went wrong, please contact NOC')
        })
    }

    const handleCheckSubmit = (event) => {
        setAlerts([])
        event.preventDefault()

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.stopPropagation();
          setAlerts([{
            severity:"info",
            message:"Please fill in all fields."
            }])
            setValidated(true);
            return
        }
        setShow(true)
        setShowSubmit(true)
        setModalHeading('Submit')
        setModalBody('Click the "Submit" button to create a new Service')
    }
    const handleSubmit = () => {
        setShowSubmit(false)

        const formData = new FormData(formRef.current);
        const data = {}
        for (let [key, value] of formData.entries()) {
            if (key in data){
                Array.isArray(data[key])?data[key].push(value):data[key] = [data[key], value]}
            else if ( value === "on" ) data[key] = true
            else data[key] = value
        }
        console.log('--->', data)

        postRemote(data)
        setModalHeading('Creating')
        setModalBody('Waiting for response from the server')
        
      };
    const handleChangeSrv = (e) => {
        const data = e.split(',')
        const id = data[0]
        const satellite = data[1]
        let srv = sat.filter(function(e) {
            return e.id === Number(id) && e.name === satellite
        })
        setSelectedSrv(srv[0]?.server)
        let reflData = [] 
        reflectors.filter(ant => ant.server === srv[0]?.server).forEach((e) =>{
            if (!reflData.includes(e.size) && e.size > 0) {
                if (isMobile && !e.controllable) {
                    return null
                }
                reflData.push(e.size)
            }
        })
        setAvailableReflectors([...reflData.sort()])
        
    }
    return (
        <>
            <Navbar></Navbar>
            <section className="pre_activation">
                <Container fluid="sm">
                    <h1 className="text-center mt-4">Pre-Activation Survey</h1>
                    <Form ref={formRef} className="m-auto mt-4" noValidate validated={validated} onSubmit={handleCheckSubmit}>
                        {is_superuser && 
                        <Row>
                            <Form.Group as={Col} xs={12} md={6} className="mb-3"> 
                                <Form.Label>
                                    Customer
                                </Form.Label>
                                <Form.Control as="select" type="select" name="customer" required>
                                    <option value="">Open select menu</option>
                                    {customers.map((e, key)=>{
                                        return <option key={key} value={`${e.id},${e.name}`}>{e.name}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Row>}
                        <Row className="mb-3">
                            <Form.Group as={Col} xs={12} md={6} className="mb-3"> 
                                <Form.Label>
                                    Activation Satellite
                                </Form.Label>
                                <Form.Control as="select" type="select" name="satellite" onChange={(e)=>handleChangeSrv(e.target.value)} required>
                                    <option value="">Open select menu</option>
                                    {sat.map((e, key)=>{
                                        return <option key={key} value={`${e.id},${e.name}`}>{e.name}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={6} className="mb-3">
                                <Form.Label>
                                    Beam
                                </Form.Label>
                                <Form.Control as="select" type="select" name="beam" onChange={(e)=>setBeam(e.target.value)} required>
                                    <option value="">Open select menu</option>
                                    <option value="Single">Single</option>
                                    <option value="Multi European" disabled>Multi European</option>
                                    <option value="Multi Regional" disabled>Multi Regional</option>
                                </Form.Control>
                            </Form.Group>
                            {beam === 'Single' || beam === ''?null:
                                <Row className="mb-3">
                                    <Form.Group as={Col} xs={12} md={6} className="mb-3">
                                        <Form.Label>Satellites</Form.Label>
                                            <Form.Control multiple  as="select" type="select" name="satellites_fixed" required>
                                            {sat.map((e, key)=>{
                                                return <option key={key} value={(e.id, e.name)}>{e.name}</option>
                                            })}
                                            </Form.Control>
                                    </Form.Group>
                                </Row>
                            }
                        </Row>
                        <h4 className="text-center mt-3 mb-3">Site and Schedule Info</h4>
                        <Row className="mb-3">
                            <Form.Group as={Col} xs={12} md={4}>
                                <Form.Label>Site Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="site_name"
                                />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4}>
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="city"
                                />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4}>
                                <Form.Label>Country</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="country"
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} xs={12} md={6} className="mb-3">
                                <Form.Label>Data Rate Down</Form.Label>
                                <Form.Control as="select" type="select" name="rate_down" required>
                                    <option value="">Open select menu</option>
                                    <option value="64">64</option>
                                    <option value="128">128</option>
                                    <option value="256">256</option>
                                    <option value="512">512</option>
                                    <option value="768">768</option>
                                    <option value="1024">1024</option>
                                    <option value="1536">1536</option>
                                    <option value="2048">2048</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={6}>
                                <Form.Label>Data Rate Up</Form.Label>
                                <Form.Control as="select" type="select" name="rate_up" required>
                                    <option value="">Open select menu</option>
                                    <option value="64">64</option>
                                    <option value="128">128</option>
                                    <option value="256">256</option>
                                    <option value="512">512</option>
                                    <option value="768">768</option>
                                    <option value="1024">1024</option>
                                    <option value="1536">1536</option>
                                    <option value="2048">2048</option>
                                </Form.Control>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} xs={12} md={6} className="mb-3">
                                <Form.Label>Contention Ratio Down</Form.Label>
                                <Form.Control as="select" type="select" name="ratio_down" required>
                                    <option value="">Open select menu</option>
                                    <option value="1:1">1:1</option>
                                    <option value="1:2">1:2</option>
                                    <option value="1:3">1:3</option>
                                    <option value="1:4">1:4</option>
                                    <option value="1:5">1:5</option>
                                    <option value="1:6">1:6</option>
                                    <option value="1:7">1:7</option>
                                    <option value="1:8">1:8</option>
                                    <option value="1:9">1:9</option>
                                    <option value="1:10">1:10</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={6}>
                                <Form.Label>Contention Ratio Up</Form.Label>
                                <Form.Control as="select" type="select" name="ratio_up" required>
                                    <option value="">Open select menu</option>
                                    <option value="1:1">1:1</option>
                                    <option value="1:2">1:2</option>
                                    <option value="1:3">1:3</option>
                                    <option value="1:4">1:4</option>
                                    <option value="1:5">1:5</option>
                                    <option value="1:6">1:6</option>
                                    <option value="1:7">1:7</option>
                                    <option value="1:8">1:8</option>
                                    <option value="1:9">1:9</option>
                                    <option value="1:10">1:10</option>
                                </Form.Control>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} xs={12} md={6} className="mb-3">
                                <Form.Label>Service Activation Date</Form.Label>
                                <Form.Control type="date" name="activation_date" required/>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={6}>
                                <Form.Label>Service Activation Time</Form.Label>
                                <Form.Control type="time" name="activation_time" required/>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} xs={12} md={6} className="mb-3">
                                <Form.Label>Latitude</Form.Label>
                                <Form.Control 
                                    type="number" 
                                    inputMode="float" 
                                    step="0.00001" 
                                    placeholder="50.85" 
                                    required
                                    name="lat"
                                    onChange={(e)=>setLat(e.target.value)}
                                />
                                <small className="form-text text-muted">{lat<0?'South':'North'}</small>

                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={6}>
                                <Form.Label>Longitude</Form.Label>
                                <Form.Control 
                                    type="number" 
                                    inputMode="float" 
                                    step="0.00001" 
                                    placeholder="7.47" 
                                    required
                                    name="lon"
                                    onChange={(e)=>setLong(e.target.value)}
                                />
                                <small className="form-text text-muted">{long<0?'West':'East'}</small>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Form.Check
                                    type="checkbox"
                                    inline
                                    label="Mobile Service"
                                    onChange={(e)=>setIsMobile(e.target.checked)}
                                    name="is_mobile"
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                        {isMobile?
                        <>
                            <Row className="mb-2">
                                <Col xs={12} md={2}>
                                    <Form.Label>GPS Input:</Form.Label>
                                </Col>
                                <Col xs={12} md={10}>
                                    <Form.Check
                                        inline
                                        name="gps_input"
                                        label="Manual"
                                        type="radio"
                                        value="Manual"
                                        onChange={(e)=>setIsNMEA(e.target.value)}
                                        />
                                    <Form.Check
                                        inline
                                        name="gps_input"
                                        label="Antenna"
                                        type="radio"
                                        value="Antenna"
                                        onChange={(e)=>setIsNMEA(e.target.value)}
                                        />
                                    <Form.Check
                                        inline
                                        name="gps_input"
                                        label="Serial/NMEA"
                                        type="radio"
                                        value="Serial/NMEA"
                                        onChange={(e)=>setIsNMEA(e.target.value)}
                                        />
                                </Col>
                            </Row>
                            {isNMEA === 'Serial/NMEA'?
                                <Row className="mb-3">
                                    <Form.Group as={Col} xs={6} md={4} className="mb-3">
                                        <Form.Label>Baudrate</Form.Label>
                                        <Form.Control type="number" name="serial_baudrate" required/>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={6} md={4} className="mb-3">
                                        <Form.Label>Data</Form.Label>
                                        <Form.Control type="number" name="serial_data" required/>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={6} md={4} className="mb-3">
                                        <Form.Label>Parity</Form.Label>
                                        <Form.Control type="number" name="serial_parity" required/>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={6} md={4} className="mb-3">
                                        <Form.Label>Stop Bit</Form.Label>
                                        <Form.Control type="number" name="serial_stop_bit" required/>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={6} md={4} className="mb-3">
                                        <Form.Label>Sample (sec)</Form.Label>
                                        <Form.Control type="number" name="serial_sample_sec" required/>
                                    </Form.Group>
                                </Row>
                            :null}
                            <Row className="mb-3">
                                <Col xs={6} md={3}>
                                    <Form.Check
                                        type="checkbox"
                                        inline
                                        label="Handshake Sig."
                                        name="handshake_sig"
                                    />
                                </Col>
                                <Col xs={6} md={3}>
                                    <Form.Check
                                        type="checkbox"
                                        inline
                                        label="Mobile Security"
                                        name="mobile_security"
                                    />
                                </Col>
                            </Row>

                        </>
                        :
                        null}
                        <h4 className="text-center mt-3 mb-3">Equipment Details</h4>
                        <Row className="mb-3">
                            <h5 className="mb-3">Antenna</h5>
                            {isMobile?
                            <>
                                <Form.Group as={Col} xs={6} md={4} className="mb-3">
                                    <Form.Label>Brand</Form.Label>
                                    <Form.Control type="text" name="antenna_brand" required/>
                                </Form.Group>
                                <Form.Group as={Col} xs={6} md={4} className="mb-3">
                                    <Form.Label>Type</Form.Label>
                                    <Form.Control type="text" name="antenna_type" required/>
                                </Form.Group>
                            </>
                            :null}
                            <Form.Group as={Col} xs={6} md={4} className="mb-3">
                                <Form.Label>Size</Form.Label>

                                <Form.Control 
                                    as="select"
                                    type="select"
                                    name="antenna_size"
                                    required
                                    disabled={selectedSrv?false:true}
                                    min={selectedBucModel.startsWith("C -")?'1.8':'0.1'}
                                >
                                    <option value="">Open select menu</option>
                                    {availableReflectors.map((e, key)=>{
                                            if (e !== undefined) {
                                                return <option key={key} value={e}>{e}</option>
                                            }
                                            return null
                                    })}
                                </Form.Control>

                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <h5 className="mb-3">BUC/HPA</h5>
                            <Form.Group as={Col} xs={6} md={4} className="mb-3">
                                <Form.Label>Brand</Form.Label>
                                <Form.Control 
                                    as="select" 
                                    type="select" 
                                    name="buc_or_hpa_brand" 
                                    required
                                    onChange={(e)=>setSelectedBuc(e.target.value)}
                                >
                                    <option value="">Open select menu</option>
                                    {bucManufacturer.map((e, key)=>{
                                        if (e !== undefined) {
                                            return <option key={key} value={e}>{e}</option>
                                        }
                                        return null
                                    })}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} xs={6} md={4} className="mb-3">
                                <Form.Label>Model</Form.Label>
                                <Form.Control 
                                    as="select"
                                    type="select"
                                    name="buc_or_hpa_model"
                                    required
                                    disabled={selectedBuc?false:true}
                                    onChange={(e)=>setSelectedBucModel(e.target.value)}
                                >
                                    <option value="">Open select menu</option>
                                    {buc.filter(buc => buc?.server === selectedSrv && buc.manufacturer === selectedBuc).map((e, key)=>{
                                        if (e !== undefined) {
                                            return <option key={key} value={e.name}>{e.name}</option>
                                        }
                                        return null
                                    })}
                                </Form.Control>
                            </Form.Group>                           
                        </Row>
                        <Row className="mb-3">
                            <h5 className="mb-3">LNB</h5>
                            <Form.Group as={Col} xs={6} md={4} className="mb-3">
                                <Form.Label>Brand</Form.Label>
                                <Form.Control
                                    as="select"
                                    type="select"
                                    name="lnb_brand"
                                    required
                                    onChange={(e)=>setSelectedLnb(e.target.value)}
                                >
                                    <option value="">Open select menu</option>
                                    {lnbManufacturer.map((e, key)=>{
                                        if (e !== undefined) {
                                            return <option key={key} value={e}>{e}</option>
                                        }
                                        return null
                                    })}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} xs={6} md={4} className="mb-3">
                                <Form.Label>Model</Form.Label>
                                <Form.Control as="select" type="select" name="lnb_model" required disabled={selectedLnb?false:true}>
                                    <option value="">Open select menu</option>
                                    {lnb.filter(lnb => lnb?.server === selectedSrv && lnb.manufacturer === selectedLnb).map((e, key)=>{
                                        if (e !== undefined) {
                                            return <option key={key} value={e.name}>{e.name}</option>
                                        }
                                        return null
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <h5 className="mb-3">iDirect Modem</h5>
                            <Form.Group as={Col} xs={6} md={4} className="mb-3">
                                <Form.Label>Model</Form.Label>
                                <Form.Control as="select" type="select" name="modem_model" required>
                                    <option value="">Open select menu</option>
                                    <option value="X1">x1</option>
                                    <option value="X3">x3</option>
                                    <option value="X5">x5</option>
                                    <option value="X7">x7</option>
                                    <option value="iQ200Board">iQ200Board</option>
                                    <option value="iQ200Rackmount">iQ200Rackmount</option>
                                    <option value="iQDesktop">iQDesktop</option>
                                    <option value="iQLTE">iQLTE</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} xs={6} md={4} className="mb-3">
                                <Form.Label>Serial Number</Form.Label>
                                <Form.Control type="text" name="modem_serial" required/>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <h5 className="mb-3">IF Cable</h5>
                            <Form.Group as={Col} xs={6} md={4} className="mb-3">
                                <Form.Label>Type</Form.Label>
                                <Form.Control type="text" name="if_cable_type" required/>
                            </Form.Group>
                            <Form.Group as={Col} xs={6} md={4} className="mb-3">
                                <Form.Label>Length(m)</Form.Label>
                                <Form.Control type="number" name="if_cable_lenght" required/>
                            </Form.Group>
                        </Row>
                        <h4 className="text-center mt-3 mb-3">Router Parameter Details</h4>
                        <Row className="mb-3">
                            <h5 className="mb-3">IP Address range</h5>
                            <Form.Group as={Col} xs={12} md={4} className="mb-3">
                                <Form.Label>IP Subnet Size:</Form.Label>
                                    <Form.Control 
                                        as="select"
                                        type="select"
                                        name="ip_subnet_size"
                                        required
                                        onChange={(e)=>setSelectedSubnet(e.target.value)}
                                        defaultValue={"30"}
                                        >
                                        <option value="30">/30</option>
                                        <option value="29">/29</option>
                                        <option value="28">/28</option>
                                        <option value="27">/27</option>
                                    </Form.Control>
                            </Form.Group>
                            {selectedSubnet?
                                <Col as={Col} xs={12} md={8} className="mb-3 my-auto pb-2">
                                    <span className="fw-light">Usable ip addresses: {2**(32-selectedSubnet)-3}</span>
                                </Col>
                            :null}
                        </Row>
                        <h4 className="text-center mt-3 mb-3">Test Equipment</h4>
                        <Row className="mb-3">
                            <h5 className="mb-3">Availability of Test Equipment</h5>
                            <Col xs={6} md={4} className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    inline
                                    label="Spectrum Analyzer"
                                    name="eqiup_spectrum_analyzer"
                                />
                            </Col>
                            <Col xs={6} md={4} className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    inline
                                    label="Satellite Finder"
                                    name="eqiup_satellite_finder"
                                />
                            </Col>
                            <Col xs={6} md={4} className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    inline
                                    label="Multimeter"
                                    name="eqiup_multimeter"
                                />
                            </Col>
                            <Col xs={6} md={4} className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    inline
                                    label="Inclinometer"
                                    name="eqiup_inclinometer"
                                />
                            </Col>
                        </Row>
                        <h4 className="text-center mt-3 mb-3">Field Technical Contact</h4>
                        <Row className="mb-3">
                            <Form.Group as={Col} xs={12} md={4} className="mb-3">
                                <Form.Label>Contact Person</Form.Label>
                                <Form.Control type="text" name="contact_person" required/>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4} className="mb-3">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control type="email" name="contact_email" required/>
                            </Form.Group>
                            <Form.Group as={Col} xs={6} md={4} className="mb-3">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control type="number" name="contact_mobile" required/>
                            </Form.Group>
                            <Form.Group as={Col} xs={6} md={4} className="mb-3">
                                <Form.Label>Skype ID</Form.Label>
                                <Form.Control type="text" name="contact_skype"/>
                            </Form.Group>
                        </Row>
                        <Button type="submit">Submit form</Button>
                    </Form>
                </Container>
            </section>
            <AlertList alerts={alerts} setAlerts={setAlerts}></AlertList>
            <Modal
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {modalHeading}
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalBody}</Modal.Body>
                <Modal.Footer>
                {showSubmit && (
                    <Button onClick={handleSubmit}>
                        Submit
                    </Button>
                )}
                <Button onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default NewServiceAndRemote
