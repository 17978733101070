import React from 'react';
import TaskItem from './TaskItem';
import Navbar from "../Navbar";
import { useAuthHeader } from "react-auth-kit";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/esm/Container';
function Tasks() {
    const [tasks, setTasks] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState("");
    const authHeader = useAuthHeader()

    React.useEffect(() => {
        const fetchTasks = async () => {
            setError('')           
            let url = `api/bcgtasks`;
            const options = {
            method: "GET",
            headers: {
                "content-type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
                Authorization: authHeader(),
            },
            url: url,
            };
            await axios(options)
            .then((resp) => resp.data)
            .then((data) =>  {
                setTasks(data);
                if (data.length === 0) setError('There are currently no active tasks.')
            })
            .catch((error) => {
                console.error(`Error: ${error}`);
                setError('Something went wrong.')
            });
            setLoading(false)
        };
        fetchTasks()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
        <Navbar/>
        <Container fluid>
            <section className="task">
                <h1 className="text-center mt-4">Tasks</h1>
                <div className="task__wrapper">
                {
                Object.entries(tasks).map( ([key, value]) => <TaskItem {...value} key={key}/>)
                } 
                </div>
                
                <div className={error.length > 0 ? "error" : "error hide"}>
                    {error}
                </div>
                {loading ? (
                    <Spinner animation="border" role="status" variant="primary" className="align-self-center">
                    <span className="visually-hidden">Loading...</span>
                    </Spinner> ) : null
                }
            </section>
        </Container>
        </>
    )
}

export default Tasks;