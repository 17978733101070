import React from 'react';
import ModalTaskItem from './ModalTaskItem';

function ModalTask({tasks, handleDeleteTask}) {

    return (
        <div className="modal_task">
            {
            Object.entries(tasks).map( ([key, value]) => <ModalTaskItem {...value} key={key} handleDeleteTask={handleDeleteTask}/>)
            }
        </div>
    )
}

export default ModalTask;