import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { useAuthHeader } from "react-auth-kit";
import axios from "axios";
import Spinner from "react-bootstrap/esm/Spinner";

function UnBlockApps({ rule_id, appsUrls, fetchBlocked }) {
  const authHeader = useAuthHeader();
  const [show, setShow] = React.useState(false);
  const [showSubmit, setShowSubmit] = React.useState(true);
  const [modalBody, setModalBody] = React.useState(<p>Click the "Submit" button to change the blocking rule</p>);

  const handleClose = () => {
    setShow(false);
    setShowSubmit(true);
    setModalBody(<p>Click the "Submit" button to change the blocking rule</p>)
    fetchBlocked()
    };
  const handleShow = () => setShow(true);

  async function handleUnBlockApp(rule_id, appsUrls){
    setShowSubmit(false);

    let url = `api/statistics/unblock?rule_id=${rule_id}`;
    const options = {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        "accept": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authHeader(),
      },
      url: url,
      data: `${JSON.stringify(appsUrls)}`
    };

    setModalBody(
        <div className="align-self-center">
          Waiting for a server response...
          <Spinner
            size="sm"
            animation="border"
            role="status"
            variant="primary"
            className="ms-2"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
    );

    await axios(options)
    .then((resp) => {
        setModalBody(
          <p>
            Blocking rule has been removed. The new configuration will be applied in 30 minutes.
          </p>
        );
    })
    .catch((error) => {
        console.error(`Error: ${error?.status}`);
        setModalBody(
            <p>
              Something went wrong, please try again later.
            </p>
        );
    })
  }

  return (
    <>
      <td><Button size="sm" className="btn_unblock" onClick={handleShow}>Unblock</Button></td>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Unblock
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
          {showSubmit ? (
            <Button onClick={() => handleUnBlockApp(rule_id, appsUrls)}>
              Submit
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UnBlockApps;
