import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { useAuthHeader } from "react-auth-kit";
import axios from "axios";
import Spinner from "react-bootstrap/esm/Spinner";

function ServicesChangeStatus({ Name, Id, Status__c, fetchTask }) {
  const authHeader = useAuthHeader();
  const [show, setShow] = React.useState(false);
  const [showSubmit, setShowSubmit] = React.useState(true);
  const [modalHeading, setModalHeading] = React.useState("");
  const [modalBody, setModalBody] = React.useState("");

  const handleClose = () => {
    setShow(false);
    setShowSubmit(true);
  };
  const handleShow = () => setShow(true);

  React.useEffect(() => {
    setModalHeading(
      Status__c === "Commercially activated" ? "Deactivation" : "Reactivation"
    );
    setModalBody(
      <p>
        Click the "Submit" button to change the status of {Name} to{" "}
        {Status__c === "Commercial suspension" ? "Activate" : "Deactivate"}
      </p>
    );
  }, [Name, Status__c]);

  const changeServiceStatus = async (id, Status__c) => {
    setShowSubmit(false);
    console.log(Status__c);
    let url = "";
    if (Status__c === "Commercially activated") {
      url = `api/service/${id}/deactivate`;
    } else if (Status__c === "Commercial suspension") {
      url = `api/service/${id}/activate`;
    } else {
      setModalBody(<p>Unexpected Service status</p>);
      return;
    }

    const options = {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        Authorization: authHeader(),
      },
      url: url,
    };
    setModalBody(
      <div className="align-self-center">
        Waiting for a server response...
        <Spinner
          size="sm"
          animation="border"
          role="status"
          variant="primary"
          className="ms-2"
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
    await axios(options)
      .then((resp) => {
        const result = resp.data;
        setModalBody(
          <p>
            {Status__c === "Commercial suspension" ? "Activate" : "Deactivate"}{" "}
            Task Created for {Name}
          </p>
        );
        fetchTask(result);
      })
      .catch((error) => {
        console.log(error);
        // console.clear()
        if (error.response.status === 409) {
          setModalBody(<p>{error.response.data.detail}</p>);
        } else if (error.response.status === 403) {
          setModalBody(
            <p>You don't have permission to change service status</p>
          );
        } else {
          setModalBody(<p>Something Went Wrong</p>);
        }
      });
  };

  return (
    <>
      <Button variant="primary" disabled={Status__c === 'Approved for activation' && true} onClick={handleShow}>
        {Status__c === "Commercial suspension" ? "Activate" : "Deactivate"}
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {modalHeading}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
          {showSubmit ? (
            <Button disabled={Status__c === 'Approved for activation' && true} onClick={() => changeServiceStatus(Id, Status__c)}>
              Submit
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ServicesChangeStatus;
