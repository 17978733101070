import Navbar from "../Navbar.jsx";
import React from "react";
import axios from "axios";
import { useAuthHeader } from "react-auth-kit";
import AlertList from "../Alert/Alert";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";

function ModCodes() {
    const [show, setShow] = React.useState(false);
    const [alerts, setAlerts] = React.useState([])
    const [sat, setSat] = React.useState([]);
    const [selectedSat, setSelectedSat] = React.useState([]);
    const [modCodes, setModCodes] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [antennaSize, setAntennaSize] = React.useState(null);
    const [modCode, setModCode] = React.useState(null);
    const [refreshModeCode, setRefreshModeCode] = React.useState(0);

    const authHeader = useAuthHeader();

    const handleClose = () => {
        setShow(false)
        setAntennaSize(null)
        setModCode(null)
    }
    React.useEffect(() => {
        const fetchSat = async () => {
            setLoading(true)
            let url = `api/remotes/satellites`;
            const options = {
            method: "GET",
            headers: {

                "Access-Control-Allow-Origin": "*",
                Authorization: authHeader(),
            },
            url: url,
            };
            await axios(options)
            .then((resp) => resp.data)
            .then((data) =>  {
                setSat([...data]);
            })
            .catch((error) => {
                console.error(`Error: ${error}`);
                setAlerts([
                    ...alerts, 
                    {
                        severity:"danger",
                        message:"Fetch Satellites: Something went wrong."
                    }
                ])
            });
            setLoading(false)
        };
        
    
        fetchSat()

    }, [])

    React.useEffect(() => {
        const fetchModCodes = async () => {
            setLoading(true)
            let url = `api/remotes/modcodes?satellite=${selectedSat}`;
            const options = {
            method: "GET",
            headers: {

                "Access-Control-Allow-Origin": "*",
                Authorization: authHeader(),
            },
            url: url,
            };
            await axios(options)
            .then((resp) => resp.data)
            .then((data) =>  {
                console.log(data)
                setModCodes([...data]);
            })
            .catch((error) => {
                console.error(`Error: ${error}`);
                setAlerts([
                    ...alerts, 
                    {
                        severity:"danger",
                        message:"Fetching ModCodes: Something went wrong."
                    }
                ])
            });
            setLoading(false)
        };
        
    
        fetchModCodes()

    }, [selectedSat, refreshModeCode])


    const handleRemoveModCodes = async (id) => {
        setLoading(true)
        let url = `api/remotes/modcodes?id=${id}`;
        const options = {
        method: "DELETE",
        headers: {

            "Access-Control-Allow-Origin": "*",
            Authorization: authHeader(),
        },
        url: url,
        };
        await axios(options)
        .then((resp) => {
            console.log(resp)
            return resp.data
        })
        .then((data) =>  {
            console.log(data)
            // setModCodes([...data]);
        })
        .catch((error) => {
            console.error(`Error: ${error}`);
            setAlerts([
                ...alerts, 
                {
                    severity:"danger",
                    message:"Delete ModCodes: Something went wrong."
                }
            ])
        });
        setLoading(false)
        setRefreshModeCode((e)=> e+1)
    };

    const handleAddModCode = async()=>{
        let url = `api/remotes/modcodes?size=${antennaSize}&satellite=${selectedSat}&modcode=${modCode}`;
        const options = {
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: authHeader(),
            },
            url: url,
            };
            await axios(options)
            .then((resp) => {
                console.log(resp)
            })
            .catch((error) => {
                console.error(`Error: ${error}`);
                setAlerts([
                    ...alerts, 
                    {
                        severity:"danger",
                        message:"Add ModCodes: Something went wrong."
                    }
                ])
            });
        handleClose()
        setRefreshModeCode((e)=> e+1)
    }

    return(
    <>
    <Navbar/>
    <Container style={{"maxWidth":"800px"}}>
        <h1 className="text-center mx-auto my-5">Nominal ModCodes</h1>
      <Row>
        <Form.Select 
        aria-label="Default select example"
        onChange={(e) => setSelectedSat(e.target.value)}
        >
        <option>Open this select menu</option>
            {sat.map((e) => {
                return <option value={e.name}>{e.name}</option>
            })}
            
        </Form.Select>
      </Row>
      
      <Row className="justify-content-center mt-4">
      
        {modCodes.length>0 &&
            modCodes.map((e)=>{
                return <Row className="m-auto mb-3" key={e.id}>
                        <InputGroup>
                            <Form.Control 
                            readOnly 
                            defaultValue={e.size}
                            />
                            <Form.Control 
                            readOnly 
                            defaultValue={e.modcode}
                            />
                            <Button 
                                variant="outline-danger"
                                className="rounded-right"
                                onClick={() => handleRemoveModCodes(e.id)}    
                                style={{borderRadius:"0 0.375rem 0.375rem 0"}}
                            >
                                Remove
                            </Button>
                        </InputGroup>
                    </Row>
            })}
            {loading && (
            <Spinner animation="border" role="status" variant="primary" className="mx-auto d-block mt-4">
              <span className="visually-hidden">Loading...</span>
            </Spinner> )
            }   
            {modCodes.length === 0 && !loading&&
                <h3 className="text-center mx-auto my-4">No ModCodes</h3>
            }

            {selectedSat.length > 0 &&
                <Button 
                    variant="outline-primary"
                    className="btn_add_modcode"
                    onClick={(e) =>  setShow(true)}    
                >
                    +
                </Button>}
      </Row>
    </Container>
    
    <AlertList alerts={alerts} setAlerts={setAlerts}></AlertList>
        <Modal
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Nominal ModCode
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup>
                    <Form.Control  
                        placeholder="Antenna Length"
                        onChange={(e) => setAntennaSize(e.target.value)}
                    />
                    <Form.Control  
                        placeholder="ModCode"
                        onChange={(e) => setModCode(e.target.value)}
                    />
                </InputGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleAddModCode}>
                    Create
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    );
}

export default ModCodes;