import Navbar from "../Navbar";
import React from "react";
import axios from "axios";
import { useAuthHeader } from "react-auth-kit";
import Moment from 'moment';

const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);
  
    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);
  
    const requestSort = (key) => {
      let direction = 'descending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'descending'
      ) {
        direction = 'ascending';
      }
      setSortConfig({ key, direction });
    };
  
    return { items: sortedItems, requestSort, sortConfig };
  };

function ServicesLogs() {
    const [logs, setLogs] = React.useState([]);
    const [error, setError] = React.useState("");
    const { items, requestSort, sortConfig } = useSortableData(logs);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return 'sort';
      }
      return sortConfig.key === name ? sortConfig.direction : 'sort';
    };

    const authHeader = useAuthHeader();

    const fetchLogs = () => {
        let url = `api/logs/services_logs`;
        const options = {
        method: "GET",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
            Authorization: authHeader(),
        },
        url: url,
        };
        axios(options)
        .then((resp) => {
            const result = resp.data;
            setLogs(result);
        })
        .catch((error) => {
            console.error(`Error: ${error}`);
            setError("Can't load logs")
        });
    };

    React.useEffect(() => {
        fetchLogs();
        requestSort('created')
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

  return (
    <div>
        <Navbar />
        <div className="container">
            <h1 className="title">Logs</h1>
            <table>
                <thead>
                    <tr>
                    <th><button type="button" onClick={() => requestSort('id')} className={getClassNamesFor('id')}>id</button></th>
                    <th><button type="button" onClick={() => requestSort('user')} className={getClassNamesFor('user')}>user</button></th>
                    <th><button type="button" onClick={() => requestSort('service')} className={getClassNamesFor('service')}>service</button></th>
                    <th><button type="button" onClick={() => requestSort('service')} className={getClassNamesFor('service')}>case</button></th>
                    <th><button type="button" onClick={() => requestSort('remotes')} className={getClassNamesFor('remotes')}>remotes</button></th>
                    <th><button type="button" onClick={() => requestSort('type')} className={getClassNamesFor('type')}>type</button></th>
                    <th><button type="button" onClick={() => requestSort('status')} className={getClassNamesFor('status')}>status</button></th>
                    <th><button type="button" onClick={() => requestSort('desc')} className={getClassNamesFor('desc')}>desc</button></th>
                    <th><button type="button" onClick={() => requestSort('created')} className={getClassNamesFor('created')}>created</button></th>
                    <th><button type="button" onClick={() => requestSort('finished')} className={getClassNamesFor('finished')}>finished</button></th>
                    </tr>
                </thead>
                <tbody>
                {items.map(log => 
                    <tr  key={log.task_id}>
                        <td data-cell="id">{log.id}</td>
                        <td data-cell="user">{log.user}</td>
                        <td data-cell="service">
                          {log.sf_service_id?<a target="_blank" rel="noopener noreferrer" href={`https://axessnetworks.lightning.force.com/lightning/r/Service__c/${log.sf_service_id}/view`}>{log.service}</a>:<span>{log.service}</span>}
                        </td>
                        <td data-cell="case">
                          {log.sf_case_id?<a target="_blank" rel="noopener noreferrer" href={`https://axessnetworks.lightning.force.com/lightning/r/Case/${log.sf_case_id}/view`}>🔗</a>:null}
                        </td>
                        <td data-cell="remotes">{log.remotes}</td>
                        <td data-cell="type">{log.type}</td>
                        <td data-cell="status" className="text-center">{log.status?'✅':'❌'}</td>
                        <td data-cell="desc">{log.desc}</td>   
                        <td data-cell="created">{log.created?Moment(log.created).format('DD/MM/YYYY HH:mm'):null}</td>     
                        <td data-cell="finished">{log.finished?Moment(log.finished).format('DD/MM/YYYY HH:mm'):null}</td> 
                    </tr>  
                )}
                </tbody>
            </table>
            <div className={error.length > 0 ? "error" : "error hide"}>
                {error}
            </div>
        </div>
    </div>
  );
}

export default ServicesLogs;
