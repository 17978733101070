import React from "react";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";

function NotFound404() {
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/`; 
    navigate(path);
  }

  return (
    <div className="d-flex align-items-center min-vh-100">
      <Container fluid="sm">
        <h1 className="text-center mt-4">404</h1>
        <h3 className="text-center">UH OH! You're lost.</h3>
        <p className="text-center">
        The page you are looking for does not exist. <br/>
        How you got here is a mystery. <br/>
        But you can click the button below to go back to the homepage.
        </p>
        <Button className="mx-auto d-block" onClick={routeChange}>Home</Button>
      </Container>
    </div>

  );
}

export default NotFound404;
