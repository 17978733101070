import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import { useAuthHeader } from "react-auth-kit";


function UserEdit({id, email, is_active, is_superuser, is_verified, customers, setUserList, sfCustomers}) {
  const authHeader = useAuthHeader();
  const [show, setShow] = React.useState(false);
  const [showSave, setShowSave] = React.useState(true);
  const [modalBody, setModalBody] = React.useState('');

  const handleClose = () => {setShow(false); setShowSave(true); setModalBody(''); fetchUsers()};
  const handleShow = () => setShow(true);

  const fetchUsers = async () => {          
    let url = `api/users/all`;
    const options = {
    method: "GET",
    headers: {
        "content-type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        Authorization: authHeader(),
    },
    url: url,
    };
    await axios(options)
    .then((resp) => resp.data)
    .then((data) =>  {
        setUserList(data);
    })
    .catch((error) => {
        console.error(`Error: ${error}`);
    });
  };


  const handleSaveUser = async (e) => {
    e.preventDefault()   
    const userEmail = e.target.Email.value;
    const userPassword = e.target.Password.value;
    const userIsActive = e.target.is_active.checked;
    const userIsSuperuser = e.target.is_superuser.checked;
    const userIsVerified = e.target.is_verified.checked;
    const userData = {
      "email": userEmail,
      "is_active": userIsActive,
      "is_superuser": userIsSuperuser,
      "is_verified": userIsVerified,
    }
    if (userPassword !== "nopassword") {
      userData["password"] = userPassword
    }
    console.log(userData)
    setShowSave(false)

    const url = `api/users/${id}`
    const options = {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: authHeader(),
        },
        url: url,
        data: userData
    };
    setModalBody(
        <div className="align-self-center">
        Waiting for a server response...
        <Spinner size="sm" animation="border" role="status" variant="primary" className="ms-2">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
        </div>
    )
    await axios(options)
    .then((resp) => {
        const result = resp.data;
        console.log(result)
        fetchUsers()
        setModalBody(
            <p>
              User Updated
            </p>
        )
    })
    .catch(error => {
      if (error.response.status === 400) {
        setModalBody(
          <p>
            {error.response.data.detail.reason}
          </p>
        )
      } else {
          console.log('Error', error.message);
          setModalBody(
            <p>
                Something went wrong
            </p>
          )
      }
    });
  }

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Edit
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        size='sm'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className='fs-6'>
            {email}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {modalBody !== ''? modalBody :
          <Form onSubmit={(e) => handleSaveUser(e)}>
            <Form.Group className="mb-3" controlId="Email">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" defaultValue={email}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="Password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" defaultValue='nopassword'/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="is_active">
              <Form.Check defaultChecked={is_active} type="checkbox" id="is_active" label="Active" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="is_verified">
              <Form.Check defaultChecked={is_verified} type="checkbox" id="is_verified" label="Verified" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="is_superuser">
              <Form.Check defaultChecked={is_superuser} type="checkbox" id="is_superuser" label="Admin" />
            </Form.Group>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>Close</Button>
              {showSave?(<Button variant="primary" type="submit">
                Save
              </Button>):null
              }
              
            </Modal.Footer>
          </Form>
        }
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UserEdit;