import Navbar from "./Navbar";
import React from "react";
import Container from "react-bootstrap/esm/Container";
import screenshot1 from '../img/screenshot1.png';
import screenshot2 from '../img/screenshot2.png';
import screenshot3 from '../img/screenshot3.png';
import Image from 'react-bootstrap/Image';

function Home() {
  return (
    <div>
      <Navbar />
      <Container fluid="sm">
        <h1 className="text-center mt-4">Welcome To AXESS</h1>
        <p className="text-center mt-4">On this portal you can manage your services.</p>
        <p className="text-center">All services you can find in the section “Services”:</p>
        <Image src={screenshot1} alt="NavBar Service" fluid className="d-flex mx-auto"/>
        <p className="text-center mt-5 mb-1">In this section, you can find Service information:</p>
        <div className="home_service_card">
          <Image src={screenshot2} alt="Service Card" fluid className="home_service_card_img"/>
          <ol className="home_service_card_list">
            <li className="home_service_card_list_item">Company Name</li>
            <li className="home_service_card_list_item">Service Name</li>
            <li className="home_service_card_list_item">Show all remotes information.</li>
            <li className="home_service_card_list_item">Current status</li>
            <li className="home_service_card_list_item">Change status</li>
          </ol>
        </div>
        <p className="text-center mt-5 mb-1">After you change the status, you will see a window with the current status of the task in the lower right corner of the page:</p>
        <Image src={screenshot3} alt="Task Status" fluid className="d-flex mx-auto"/>
        <p className="text-center">If the remote isn’t online within 24 hours, the task will be canceled.</p>
        <p className="text-center">If you want to cancel task, please contact to noc-emea@axessnet.com</p>
      </Container>
    </div>
  );
}

export default Home;
