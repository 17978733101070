import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import { useAuthHeader } from "react-auth-kit";
import axios from "axios";
import Spinner from 'react-bootstrap/esm/Spinner';


function TaskRevoke({task_id, task_status}) {
    const authHeader = useAuthHeader();
    const [show, setShow] = React.useState(false);
    const [showSubmit, setShowSubmit] = React.useState(true);
    const [modalBody, setModalBody] = React.useState('');

    const handleClose = () => {setShow(false); setShowSubmit(true); setModalBody(
        <p>
            Click the "Submit" button to revoke Task
        </p>
    )};
    const handleShow = () => setShow(true);

    React.useEffect(() => {
            setModalBody(
                <p>
                    Click the "Submit" button to revoke Task
                </p>
            )
        },[]
    )
    
    const handleCancelTask = async (task_id) => {
        setShowSubmit(false)
        const url = `api/bcgtasks/${task_id}`
        const options = {
            method: "DELETE",
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              "Access-Control-Allow-Origin": "*",
              Authorization: authHeader(),
            },
            url: url,
        };
        setModalBody(
            <div className="align-self-center">
            Waiting for a server response...
            <Spinner size="sm" animation="border" role="status" variant="primary" className="ms-2">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
            </div>
        )
        await axios(options)
        .then((resp) => {
            const result = resp.data;
            setModalBody(
                <p>
                    Task will be revoked soon
                </p>
            );
            return result
        })
        .catch((error) => {
            console.error(`Error: ${error}`);
            console.log(error.response.data);
            console.log(error.response.headers);
            if (error.response.status === 503){
                setModalBody(
                    <p>
                        NMS server is busy, try again later.
                    </p>
                )
            } else {
                setModalBody(
                    <p>
                        Something went wrong, try again later.
                    </p>
                )
            }
        });
    }

    return (
        <>
        <Button variant="primary" onClick={handleShow} disabled={task_status === 'REVOKED'?true:false} className='mt-1'>
            Revoke Task
        </Button>
        <Modal
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Revoke Task
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {modalBody}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose}>Close</Button>
                {showSubmit?<Button onClick={() => handleCancelTask(task_id)}>Submit</Button>:null}
            </Modal.Footer>
        </Modal>
        </>
    );
}

export default TaskRevoke;