import React from "react";
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { useAuthHeader } from "react-auth-kit";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import UnBlockApps from "./UnBlockAppOnFW";

function BlockedApps({service_name, network='', return_empty=false, updateBlocked}) {
  const [blocked, setBlocked] = React.useState({})
  const [hasLoaded, setHasLoaded] = React.useState(false)
  const authHeader = useAuthHeader();


  const fetchBlocked = React.useCallback(async ()=>{
    let NewBlocked = {...blocked}

    let url = 'api/statistics/blocked-apps'
    if (service_name.length !== 0) url += `?service_name=${service_name}`;
    else if (network.length !== 0) url += `?network=${encodeURIComponent(network)}`;
    const options = {
      method: "GET",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        Authorization: authHeader(),
      },
      url: url,
    };
    await axios(options)
    .then((resp) => {
        const result = resp.data;
        NewBlocked = {...result}
    })
    .catch((error) => {
        console.error(`Error: ${error}`);
    })
    setBlocked(NewBlocked)
    setHasLoaded(true)
  },[]);

  React.useEffect(() => {
    fetchBlocked()
  }, [fetchBlocked, updateBlocked]);

  return(
    hasLoaded? (Object.keys(blocked).length > 0)?
    <Row className="m-auto">
        <Col md={12} xl={6}>
          <h5 className="text-center mt-4 mb-0">Blocked</h5>
          <Table striped bordered hover size="sm" responsive="sm">
            <thead className="thead-dark">
              <tr>
                <th>App</th>
                <th className="fitContent"></th>
              </tr>
            </thead>
            <tbody>
            {Object.entries(blocked).map(([id, value])=> {
                let appsEntries = []
                value?.applications?.applications?.forEach((app)=>
                  appsEntries.push(
                    <tr key={app.id}>
                      <td data-cell="Name">{app.type}: {app.name}</td>
                      <UnBlockApps rule_id={id} appsUrls={{'applications':{'applications':[app]}}} fetchBlocked={fetchBlocked}/>
                    </tr>
                  )
                )
                value?.applications?.inlineApplicationFilters?.forEach((app)=>{
                  Object.entries(app).map(([key, appFilter])=> 
                    {if (Array.isArray(appFilter)) {
                      appFilter.forEach((e) => {
                        appsEntries.push(
                          <tr key={key+'-'+e.id}>
                            <td data-cell="Name">{e.type}: {e.name}</td>
                            <UnBlockApps rule_id={id} appsUrls={{'applications':{'inlineApplicationFilters':[{[key]:[e]}]}}} fetchBlocked={fetchBlocked}/>
                          </tr>
                        )
                    })} else appsEntries.push(
                      <tr key={key+'-'+appFilter}>
                        <td data-cell="Name">{key}: {appFilter}</td>
                        <UnBlockApps rule_id={id} appsUrls={{'applications':{'inlineApplicationFilters':[{[key]:appFilter}]}}} fetchBlocked={fetchBlocked}/>
                      </tr>
                    )
                  }
                    )
                })
                value?.urls?.literals?.forEach((url,index) => 
                  appsEntries.push(
                    <tr key={index+'-'+url.url}>
                      <td data-cell="Name">{url.type}: {url.url}</td>
                      <UnBlockApps rule_id={id} appsUrls={{'urls':{'literals':[url]}}} fetchBlocked={fetchBlocked}/>
                    </tr>
                  )
                )
                value?.urls?.objects?.forEach((object,index) => 
                  appsEntries.push(
                    <tr key={object.id}>
                      <td data-cell="Name">{object.type}: {object.name}</td>
                      <UnBlockApps rule_id={id} appsUrls={{'urls':{'objects':[object]}}} fetchBlocked={fetchBlocked}/>
                    </tr>
                  )
                )
                value?.urls?.urlCategoriesWithReputation?.forEach((catObj,index) => 
                    appsEntries.push(
                      <tr key={'urlCategoriesWithReputation-'+index}>
                        <td data-cell="Name">{catObj.type}: {catObj.category.name}, {catObj.reputation}</td>
                        <UnBlockApps rule_id={id} appsUrls={{'urls':{'urlCategoriesWithReputation':[catObj]}}} fetchBlocked={fetchBlocked}/>
                      </tr>
                    )
                )
                return appsEntries
            })}
            </tbody>
          </Table>
        </Col>
    </Row>
    :(hasLoaded && return_empty) &&
      <Row className="m-auto">
          <Col>
            <h5 className="text-center mt-4 mb-0">'No Blocking rules 🌐'</h5>
          </Col>
      </Row>
    :null
  )
}

export default BlockedApps;