import React from "react";
import Navbar from "../Navbar";
import {useLocation} from "react-router-dom";
import RemoteStatisticsBlock from "./RemoteStatisticsBlock";
import axios from "axios";
import { useAuthHeader } from "react-auth-kit";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from "react-router-dom";

function RemoteStatistics() {
    const [loading, setLoading] = React.useState(true);
    const [remoteStatistic, setRemoteStatistic] = React.useState([])
    const authHeader = useAuthHeader();
    const location = useLocation();
    const navigate = useNavigate();
    let propsRemotes = location.state?.remotes;
    if (propsRemotes === undefined) {
        propsRemotes = []
        navigate('/services');
    }
    const uniqueRemotes = propsRemotes.reduce((accumulator, current) => {
        if (!accumulator.find((item) => item.Name === current.Name && item.DID__c === current.DID__c)) {
            accumulator.push(current);
        }
        return accumulator;
    }, []);



    const fetchStatisticsData = React.useCallback(async ()=>{
        let data = [...remoteStatistic]
        await Promise.all(uniqueRemotes.map(async (remote) => {
            let url = `api/statistics/?remote_name=${remote.Name}&remote_did=${remote.DID__c}`;
            const options = {
            method: "GET",
            headers: {
                "content-type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
                Authorization: authHeader(),
            },
            url: url,
            };
            await axios(options)
            .then((resp) => {
                const result = resp.data;
                result['name'] = remote.Name
                result['did'] = remote.DID__c
                data.push(result)
            })
            .catch((error) => {
                console.error(`Error: ${error}`);
            });
        }));
        
        setRemoteStatistic(data)
        setLoading(false)
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        fetchStatisticsData();
      }, [fetchStatisticsData]);
    return (
        <>
            <Navbar />
            {loading ? (
                <div className="d-flex vh-100">
                    <Spinner animation="border" role="status" variant="primary" className="d-block m-auto">
                    <span className="visually-hidden">Loading...</span>
                    </Spinner> 
                </div>
            ) : ""} 
            <RemoteStatisticsBlock remoteStatistic={remoteStatistic} fetchStatisticsData={fetchStatisticsData} service_name={location.state?.service_name}/>
        </>

    )
}

export default RemoteStatistics;