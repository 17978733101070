import React from "react";
import logo from "../../img/logo_axess.png";
import { useDocumentTitle } from "../useDocumentTitle";
import { useSignIn } from "react-auth-kit";
import axios from "axios";

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


function Login() {
  useDocumentTitle("Login");

  const [error, setError] = React.useState("");
  const signIn = useSignIn();

  const onSubmit = async (event) => {
    event.preventDefault();
    const user = event.target.elements.Email.value;
    const password = event.target.elements.Password.value;
    setError("");

    let body = new URLSearchParams();
    body.append("username", user);
    body.append("password", password);

    const options = {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
      },
      data: body,
      url: `api/auth/login`,
    };

    try {
      const response = await axios(options);

      const token = response.data.access_token;

      signIn({
        token: token,
        expiresIn: 60,
        tokenType: "Bearer",
        authState: {email:user},
        refreshToken: token,
        refreshTokenExpireIn: 59
      });
    } catch (error) {
      if (error && error instanceof axios.AxiosError) {
        if (error.response?.data.detail === "LOGIN_BAD_CREDENTIALS") {
          setError("Invalid username or password");
        } else if (error.message === "Network Error") {
          setError("Server is busy, please try again later.");
        } else setError("Server is busy, please try again later.");
      } else if (error && error instanceof Error) setError(error.message);
    }
  };
  return (

    <Container fluid="sm">
      <section className="login">
          <Form className='mx-auto needs-validation' onSubmit={onSubmit}>
            <img src={logo} alt="" className="login__logo" />
            <Form.Group className="mb-3" controlId="Email">
              <Form.Label className="fs-5">Email:</Form.Label>
              <Form.Control className="fs-5" type="email" placeholder="Enter email" required/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="Password">
              <Form.Label className="fs-5">Password:</Form.Label>
              <Form.Control className="fs-5" type="password" placeholder="Password" required/>
            </Form.Group>
            <div className="login__error">{error}</div>
            <Button variant="primary" type="submit" className="w-100 mt-3 p-2">
              Login
            </Button>
          </Form>
      </section>
    </Container>
  );
}

export default Login;
