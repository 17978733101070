import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useAuthHeader } from "react-auth-kit";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';


function UserEditCustomers({id, customers, setUserList, sfCustomers}) {
  const authHeader = useAuthHeader();
  const [show, setShow] = React.useState(false);
  const [showButton, setShowButton] = React.useState(true);
  const [modalBody, setModalBody] = React.useState('');
  const [userCustomers, setUserCustomers] = React.useState(customers);
  const [customerDelete, setCustomerDelete] = React.useState();
  const [customerAdd, setCustomerAdd] = React.useState();

  const handleClose = () => {setShow(false); setModalBody('');};
  const handleShow = () => setShow(true);


  const addCustomer = () => {
    setShowButton(false)
    for (var i in sfCustomers) {
      if (sfCustomers[i].id === customerAdd) {
        for (var r in userCustomers) {
          if(userCustomers[r].id === customerAdd){
            return;
          }
        }
        handleChangeCustomer({"user_id":id, "customer_id":customerAdd, 'action':'PATCH'})
      }
    }
    setShowButton(true)
  }

  const deleteCustomer = () => {
    setShowButton(false)
    for (var i in userCustomers) {
      if (userCustomers[i].id === customerDelete) {
        handleChangeCustomer({"user_id":id, "customer_id":customerDelete, 'action':'DELETE'})
        return;
      }
    }
    setShowButton(true)
  }


  const handleChangeCustomer = async ({user_id, customer_id, action}) => {

    const url = `api/customer/?user_id=${user_id}&customer_id=${customer_id}`
    const options = {
        method: action,
        headers: {
          "accept": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: authHeader(),
        },
        url: url,
    };
    await axios(options)
    .then((resp) => {
        setUserCustomers([...resp.data.customers]);
        setShowButton(true)
    })
    .catch(error => {
        console.log(error)
      if (error.response.status === 400) {
        setModalBody(
          <p>
            {error.response.data.detail.reason}
          </p>
        )
      } else {
          console.log('Error', error.message);
          setModalBody(
            <p>
                Something went wrong
            </p>
          )
      }
    });
  }

  return (
    <>
      <Button variant="secondary" onClick={handleShow}>
        Accounts
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        size='lg'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            User Accounts
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {modalBody !== ''? modalBody :
          <Form>
            <Row className="g-3 d-flex align-items-center justify-content-center">
              <Col xs={5}>
                  <label>Available Customers</label>
                  <select id="avaliable_customer" multiple="" className="form-control" size="10" 
                    onChange={e => {setCustomerAdd(e.target.value);}}
                  >
                  {sfCustomers.map((sfCustomer) => (
                    <option key={sfCustomer.id} id={sfCustomer.id} value={sfCustomer.id}>{sfCustomer.name}</option>
                  ))}
                  </select>
              </Col>
              <Col xs={1} className="d-flex align-items-center justify-content-center">
                  <div className="btn-group-vertical align-self-center">
                    {showButton? 
                    <>
                    <span id="add_customer" className="btn btn-outline-success btn-sm" onClick={addCustomer}>Add</span>
                    <span id="remove_customer" className="btn btn-outline-danger btn-sm" onClick={deleteCustomer}>Remove</span>
                    </>
                    : <Spinner></Spinner>}
                      
                  </div>
              </Col>
              <Col xs={5}>
                  <label>Selected Customers</label>
                  <select id="user_customers" multiple="" className="form-control" size="10" 
                    onChange={e => {setCustomerDelete(e.target.value);}} options={userCustomers}
                  >
                    {userCustomers.map((userCustomer) => (
                        <option key={userCustomer.id} id={userCustomer.id} value={userCustomer.id}>{userCustomer.name}</option>
                    ))}
                  </select>
              </Col>
          </Row>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>Close</Button>              
            </Modal.Footer>
          </Form>
        }
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UserEditCustomers;