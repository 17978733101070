import { Map, Marker } from "pigeon-maps"
import React from "react"

function RemoteLocation({geo}){
    const lat = parseFloat(geo.split(' ')[0])
    const lon = parseFloat(geo.split(' ')[1])

    const [hue, setHue] = React.useState(0)
    const color = `hsl(${hue % 360}deg 39% 70%)`

    return (
      
        <Map height={200} defaultCenter={[lat, lon]} defaultZoom={5}>
          <Marker 
            width={25}
            anchor={[lat, lon]} 
            color={color} 
            onClick={() => setHue(hue + 20)} 
          />
        </Map>
    )
}

export default RemoteLocation