import Navbar from "../Navbar";
import React from "react";
import axios from "axios";
import { useAuthHeader } from "react-auth-kit";
import ServiceSearchList from './ServiceSearchList';
import ModalTask from '../Modal/ModalTask';

import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

function Services() {
  const authHeader = useAuthHeader();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [services, setServices] = React.useState([]);
  const [tasks, setTasks] = React.useState({});

  React.useEffect(() => {
    getAllServices();
    fetchAllTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (Object.keys(tasks).length > 0){
        Object.entries(tasks).forEach( ([key, value]) => {
          if (value.task_status === 'PROGRESS_STATE' || value.task_status === 'RETRY'){
            fetchTask(key)
          }
        })
      }
    }, 5000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  const handleDeleteTask = (id) => {
    setTasks(({ [id]: value, ...tasks }) => tasks);
  }

  const fetchTask = async (task) => {
    let url = `api/bcgtasks/${task}`;
    const options = {
    method: "GET",
    headers: {
        "content-type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        Authorization: authHeader(),
    },
    url: url,
    };
    await axios(options)
    .then((resp) => {
        const result = resp.data;
        let obj = {};
        obj[task] = result
        setTasks(prev => ({...prev, ...obj}));
        if (tasks[task] !== result && result['task_status'] === "SUCCESS"){
          getAllServices()
        }
    })
    .catch((error) => {
        console.error(`Error: ${error}`);
        getAllServices()
    });
  };

  const getAllServices = async () => {
    let body = new URLSearchParams();
    const options = {
      method: "GET",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        Authorization: authHeader(),
      },
      data: body,
      url: "api/service/",
    };
    await axios(options)
      .then((resp) => {
        const allServices = resp.data;
        if (resp.status === 200) {
          setLoading(false);
          setError("");
          setServices([...allServices]);
          if (allServices.length === 0) setError("You don't have any services");
        }
      })
      .catch((err) => {
        setLoading(false);
        if (axios.isAxiosError(err)) {
          if (err.status) {
            setError(err.response.data.detail);
          } else {
            setError("Something went wrong, please try again later");
          }
        } else {
          console.error(`Error: ${err}`);
        }
      });
  };

  const fetchAllTasks = async () => {
    let url = `api/bcgtasks/user/`;
    const options = {
    method: "GET",
    headers: {
        "content-type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        Authorization: authHeader(),
    },
    url: url,
    };
    await axios(options)
    .then((resp) => {
        const result = resp.data;
        let obj = {};
        result.forEach(dict => obj[dict['task_id']] = dict)
        setTasks(prev => ({...prev, ...obj}));
    })
    .catch((error) => {
        console.error(`Error: ${error}`);
    });
  };

  return (
    <div>
      <Navbar />
      <Container fluid>
        <section className="services justify-content-center">
          <h1 className="text-center">Services</h1>
          <ServiceSearchList services={services} fetchTask={fetchTask}/>
          {loading ? (
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner> ) : ""
          }
          <div className={error.length > 0 ? "error" : "error hide"}>
            {error}
          </div>
        </section>
      </Container>
      <ModalTask tasks={tasks} handleDeleteTask={handleDeleteTask} />
    </div>
  );
}

export default Services;
