import Navbar from "../Navbar";
import React from "react";
import axios from "axios";
import { useAuthHeader } from "react-auth-kit";
import UserEdit from './UserEdit.jsx';
import UserEditCustomers from "./UserEditCustomers";
import UserAdd from "./UserAdd";

function User() {

    const [userList, setUserList] = React.useState([])
    // const [error, setError] = React.useState([])
    const [sfCustomers, setSfCustomers] = React.useState([]);


    const authHeader = useAuthHeader();
    
    React.useEffect(() => {
        const fetchUsers = async () => {
            // setError('')           
            let url = `api/users/all`;
            const options = {
            method: "GET",
            headers: {
                "content-type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
                Authorization: authHeader(),
            },
            url: url,
            };
            await axios(options)
            .then((resp) => resp.data)
            .then((data) =>  {
                setUserList(data);
            })
            .catch((error) => {
                console.error(`Error: ${error}`);
                // setError('Something went wrong.')
            });
        };
        const fetchCustomers = async () => {          
            let url = `api/customer/`;
            const options = {
            method: "GET",
            headers: {
                "content-type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
                Authorization: authHeader(),
            },
            url: url,
            };
            await axios(options)
            .then((resp) => resp.data)
            .then((data) => 
                data.sort((a, b) => {
                    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                    return -1;
                    }
                    if (nameA > nameB) {
                    return 1;
                    }
                
                    // names must be equal
                    return 0;
                })
            )
            .then((sort_data) =>  {setSfCustomers(sort_data)})
            .catch((error) => {
            console.log("Can't load Customers list")
            });
        };
    
        fetchCustomers();
        fetchUsers()
    }, [userList])// eslint-disable-line react-hooks/exhaustive-deps

    return(
    <>
    <Navbar/>
    <div className="user">
        <div className="container">
            <h1 className="text-center mt-4">Users</h1>
            <table>
                <thead>
                    <tr>
                    <th><button>user</button></th>
                    <th><button>active</button></th>
                    <th><button>superuser</button></th>
                    <th><button>customers</button></th>
                    <th></th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                {userList.map(user => 
                    <tr  key={user.id}>
                        <td data-cell="user">{user.email}</td>
                        <td data-cell="active">{user.is_active?'True':'False'}</td>
                        <td data-cell="superuser">{user.is_superuser?'True':'False'}</td>
                        <td data-cell="customers">{user.customers.map(customer => (<div key={customer.id}>{customer.name}</div>))}</td>
                        <td><UserEdit {...user} key={user.id} setUserList={setUserList} sfCustomers={sfCustomers}/></td>
                        <td><UserEditCustomers {...user} key={user.id} setUserList={setUserList} sfCustomers={sfCustomers}/></td>
                    </tr>  
                )}
                </tbody>
            </table>
            <UserAdd setUserList={setUserList}></UserAdd>
        </div>
    </div>
    </>
    );
}

export default User;