import ServicesChangeStatus from "./ServicesChangeStatus";
import React from "react";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {Link} from "react-router-dom";
import { useAuthHeader } from "react-auth-kit";
import axios from "axios";
import AlertList from "../Alert/Alert";

function ServiceHtml({
  Id,
  Account__r,
  Name,
  Name__c,
  Status__c,
  Remotes,
  fetchTask
}) {
  const [alerts, setAlerts] = React.useState([]);

  const authHeader = useAuthHeader();

  function getStatus(modemStatus) {
    let classStatus = 'services__wrapper_item_status__c '
    if (modemStatus === 'Commercial suspension') {
      classStatus += 'deactivated'
    } else if (modemStatus === 'Commercially activated') {
      classStatus += 'activated'
    } else if (modemStatus === 'Approved for activation') {
      classStatus += 'approved'
    }
    return classStatus
  }

  const handleDownloadOptionsFile = async () => {
    let remote = []
    Remotes.forEach((e) => {
      if (!remote.includes(e.Name)){
        remote.push(e.Name)
      }
    })
    if (remote.length == 1) {
      const r = Remotes[0]
      const options = {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: authHeader(),
        },
        url: `api/remotes/optionsfile.opt?server=${r.NMS_server__c}&remote_id=${r.ID__c}`,
      };
      await axios(options)
        .then((resp) => {
            const result = resp.data;
            const blob = new Blob([result], { type: "text/plain" });
            const url = URL.createObjectURL(blob);
            const optFile = document.createElement('a');
            optFile.href = url
            optFile.download = 'config.opt';
            optFile.click();
        })
        .catch((error) => {
            console.error(`Error: ${error}`);
            setAlerts([
                ...alerts, 
                {
                    severity:"danger",
                    message:"Something went wrong."
                }
            ])
        });

    } else {
      setAlerts([
          ...alerts, 
          {
              severity:"info",
              message:"No remote for the Service, please contact NOC."
          }
      ])
    }
  }

  return (
    <div className="services__wrapper_item" key={Id}>
        <div className={ Remotes.filter(e => e.isOnline === true).length > 0? 'services__wrapper_item_status online' : 'services__wrapper_item_status offline'}>
        { Remotes.filter(e => e.isOnline === true).length > 0? 'Online' : 'Offline'}
        </div>
      <div className="services__wrapper_item_block">
        <div className="services__wrapper_item_account">{Account__r}</div>
        <div className="services__wrapper_item_srv">{Name}</div>
      </div>
      <div className="services__wrapper_item_name__c">{Name__c}</div>
      <div className="remote_open">     
        <OverlayTrigger
          trigger="click"
          key={Id}
          placement="bottom"
          rootClose
          overlay={
            <Popover id={`popover-positioned-bottom`}>
              <Popover.Header as="h3">{Name} Remotes</Popover.Header>
              <Popover.Body>
              <div className="services__wrapper_item_remote_data">
                {Remotes.map((remote) => (
                <div className="remote" key={remote['Satellite__c']+remote['DID__c']}>
                    <div className="remote_name">{remote['Name']}</div>       
                    <div className="remote_satellite">{remote['Satellite__c']}</div>
                    <div className="remote_cir_mir">
                        <div className="remote_cir_mir_wrapper">
                          <div className="remote_cir_mir_title">UpCIR</div>
                          <div className="remote_cir_mir_value">{remote['UpCIR__c']}</div>
                        </div>
                        <div className="remote_cir_mir_wrapper">
                          <div className="remote_cir_mir_title">DownCIR</div>
                          <div className="remote_cir_mir_value">{remote['DownCIR__c']}</div>
                        </div>
                        <div className="remote_cir_mir_wrapper">
                          <div className="remote_cir_mir_title">UpMIR</div>
                          <div className="remote_cir_mir_value">{remote['UpMIR__c']}</div>
                        </div>
                        <div className="remote_cir_mir_wrapper">
                          <div className="remote_cir_mir_title">DownMIR</div>
                          <div className="remote_cir_mir_value">{remote['DownMIR__c']}</div>
                        </div>
                    </div>
                    <hr />
                </div>
                
                ))}
                {Remotes.length === 0? <p className="text-center">Not found📡</p>:""}
              </div>
              </Popover.Body>
            </Popover>
          }
        >
          <span className="remote_open_remote" disabled={Remotes.length === 0 && true }>Show Remotes</span>
        </OverlayTrigger>
        <Link 
          to="/statistics"
          state={{remotes: Remotes, service_name:Name}}
          className={Remotes.length === 0 ? "remote_open_statistics_disable" : "remote_open_statistics" }
        >
          Show Statistics
        </Link>

      </div>

      <div className="services__wrapper_item_block">
        <div className={getStatus(Status__c)}>
          <span>{Status__c}</span>
          <span 
            className="remote_open_options_file" 
            disabled={Remotes.length === 0 && true }
            onClick={handleDownloadOptionsFile}
          >
            Download opt file
          </span>
        </div>
        <ServicesChangeStatus Name={Name} Id={Id} Status__c={Status__c} fetchTask={fetchTask}/>
      </div>
      <AlertList alerts={alerts} setAlerts={setAlerts}></AlertList>
    </div>
  );
}

export default ServiceHtml;
