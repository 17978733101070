import logo from "../icons/icon.png";
import { useSignOut } from "react-auth-kit";
import { useLocation } from "react-router-dom";
import React from "react";
import jwt_decode from 'jwt-decode';
import {useAuthHeader} from 'react-auth-kit'
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


function Header() {
  
  const authHeader = useAuthHeader()
  const token = authHeader().replace('Bearer ','')
  const is_superuser = jwt_decode(token).is_superuser

  function GetNavLinkClass(path) {
    const location = useLocation();

    if(Object.prototype.toString.call(path) === '[object Array]') {
      return path.includes(location.pathname)?true:false;
    }
    return location.pathname === path?true:false;
  }

  return (
    <Navbar bg="primary" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img
            src={logo}
            width="35"
            height="35"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
        <Nav className="me-auto">
          <NavDropdown title="Services" id="service-dropdown" active={GetNavLinkClass(["/services", "/tech-suspension", "/services-logs"])}>
            <NavDropdown.Item href="/services" active={GetNavLinkClass("/services")}>Services</NavDropdown.Item>
            {is_superuser?
            <>
              <NavDropdown.Item href="/tech-suspension" active={GetNavLinkClass("/tech-suspension")}>Technical suspension</NavDropdown.Item>
              <NavDropdown.Item href="/services-logs" active={GetNavLinkClass("/services-logs")}>Services Logs</NavDropdown.Item>
            </>
            :null}
          </NavDropdown>
          {is_superuser?(
            <>
              <Nav.Link href="/fw-block" active={GetNavLinkClass("/fw-block")}>FW Block</Nav.Link>
              <Nav.Link href="/tasks" active={GetNavLinkClass("/tasks")}>Tasks</Nav.Link>
              <Nav.Link href="/pre-activation-survey" active={GetNavLinkClass("/pre-activation-survey")}>Create Service</Nav.Link>
            </>
          ):''}
          {is_superuser &&
            <NavDropdown title="Settings" id="service-dropdown" active={GetNavLinkClass(["/user", "/modcodes"])}>
              <>
                <NavDropdown.Item href="/user" active={GetNavLinkClass("/user")}>Users</NavDropdown.Item>
                <NavDropdown.Item href="/modcodes" active={GetNavLinkClass("/modcodes")}>Nominal ModCode</NavDropdown.Item>
              </>
            </NavDropdown>
          }
        </Nav>
        
        <Form className="d-flex">
          <Button variant="outline-light" onClick={useSignOut()}>Logout</Button>
        </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
