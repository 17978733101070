import React from "react";
import LineChartTimeSeries from "./LineChartTimeSeries";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TopApps from "./TopApps";
import RemoteLocation from "./RemoteLocation";
import BlockedApps from "./BlockedApps";

function RemoteStatisticsBlock({remoteStatistic, fetchStatisticsData, service_name}) {
    const [updateBlocked, setUpdateBlocked] = React.useState(0)

    const handleUpdateBlocked = () => {
        setUpdateBlocked(updateBlocked+1)
    }
  return (
    <Container fluid>
        {remoteStatistic.map((DataObj, index) => (
            <Row key={index}>
                <Col md={12}>
                    <h4 className="text-center mt-4 mb-3">{DataObj["name"]}</h4>
                </Col>
                <Row xs={1} md={2} className="justify-content-md-center">
                    <Row md={1} className="justify-content-md-center">
                        <Col className="text-center">
                            <div className="">Activation Status: </div>
                            {DataObj?.State_01?.[0]?.value === 1?
                            <div className="statistics_status activated">Activated</div>:
                            <div className="statistics_status deactivated">Deactivated</div>}
                        </Col>
                        <Col className="text-center">
                            <div className="">Satellite: </div>
                            <div className="statistics_satellite">{DataObj?.Satellite?.[0]?DataObj["Satellite"][0]['value']:'Offline'}</div>
                        </Col>                        
                    </Row>
                    <Col md={6}>
                            {DataObj?.LatLon?.[0]?.value?
                                (
                                    <RemoteLocation geo={DataObj.LatLon[0].value}></RemoteLocation>
                                ):
                                'Deactivated'
                            }
                    </Col>
                </Row>


                <Col md={12} xl={6} xxl={4}>
                    <LineChartTimeSeries 
                        name='Bits received' 
                        name2='Bits sent' 
                        data={DataObj["Bits_recieved_wo_BCAST"]} 
                        data2={DataObj["Bits_sent"]} 
                        title="Traffic" 
                        unit='bps' 
                        toFixed='0'
                    />
                </Col>
                <Col md={12} xl={6} xxl={4}>
                    <LineChartTimeSeries 
                        name='DownSNR' 
                        data={DataObj["DownSNR"]} 
                        title="DownSNR" 
                        unit='bB' 
                        toFixed='1' 
                    />
                </Col>
                <Col md={12} xl={6} xxl={4}>
                    <LineChartTimeSeries 
                    name='ModCod' 
                    data={DataObj["ModCod"]} 
                    title="ModCod" 
                    unit='' 
                    toFixed='0' 
                />
                </Col>
                <Col md={12} xl={6} xxl={4}>
                    <LineChartTimeSeries 
                        name='Ping' 
                        data={DataObj["Ping"]} 
                        title="Ping" 
                        unit='ms' 
                        toFixed='0' 
                    />
                </Col>
                <Col md={12} xl={6} xxl={4}>
                    <LineChartTimeSeries 
                        name='Temperature' 
                        data={DataObj["Temperature"]} 
                        title="Temperature" 
                        unit='°C' 
                        toFixed='1' 
                    />
                </Col>
                <Col md={12} xl={6} xxl={4}>
                    <LineChartTimeSeries 
                        name='C/No' 
                        data={DataObj["Upstream_CNo"]} 
                        title="C/No" 
                        unit='' 
                        toFixed='1' 
                    />
                </Col>
                <Col md={12} xl={6} xxl={4}>
                    <LineChartTimeSeries 
                        name='Tx Power' 
                        data={DataObj["Upstream_Tx_Power"]} 
                        title="Upstream Tx Power" 
                        unit='dBm' 
                        toFixed='1' 
                    />
                </Col>
                <Col md={12} xl={6} xxl={4}>
                    <LineChartTimeSeries 
                        name='CRC8Errors' 
                        name2='CRC32Errors' 
                        data={DataObj["CRC8Errors"]} 
                        data2={DataObj["CRC32Errors"]} 
                        title="Errors" 
                        unit='err' 
                        toFixed='0' 
                    />
                </Col>
                {(DataObj?.["IPs"]?.length > 0)?
                        <TopApps 
                            name='TopApps' 
                            data={DataObj["IPs"][0]['value']}
                            service_name={service_name}
                            title="Top Apps" 
                            unit='' 
                            toFixed='0' 
                            updateBlocked={updateBlocked}
                            setUpdateBlocked={setUpdateBlocked}
                        />
                :''}
                {(DataObj?.["IPs"]?.length > 0)?
                        <BlockedApps 
                            // network={DataObj["IPs"][0]['value']}
                            service_name={service_name}
                            updateBlocked={updateBlocked}
                        />
                :''}
                
            </Row>
            )         
        )}
        
    </Container>
)}

export default RemoteStatisticsBlock;
