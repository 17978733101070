import React from "react";
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { useAuthHeader } from "react-auth-kit";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BlockApp from "./BlockAppOnFW";

function TopApps({title, data, service_name, setUpdateBlocked, updateBlocked}) {
  const [topUpload, setTopUpload] = React.useState([])
  const [topDownload, setDownload] = React.useState([])
  const [hasLoaded, setHasLoaded] = React.useState(false)
  const authHeader = useAuthHeader();

  const fetchAppData = React.useCallback(async ()=>{
    let NewtopUpload = [...topUpload]
    let NewtopDownload = [...topDownload]

    let url = `api/statistics/top_apps?ip_addresses=${data}&time_range=360`;
    const options = {
    method: "GET",
    headers: {
        "content-type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        Authorization: authHeader(),
    },
    url: url,
    };
    await axios(options)
    .then((resp) => {
        const result = resp.data;
        NewtopUpload = [...result['upload']]
        NewtopDownload = [...result['download']]
    })
    .catch((error) => {
        console.error(`Error: ${error}`);
    })
    setTopUpload(NewtopUpload)
    setDownload(NewtopDownload)
    if (NewtopDownload.length > 0 && NewtopDownload.length > 0){
      setHasLoaded(true)
    }
  },[]);

  React.useEffect(() => {
      fetchAppData();
    }, [fetchAppData]);

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1000
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  return(
    hasLoaded?
    <Row className="m-auto">
      <Col md={12} xl={6}>
        <h5 className="text-center mt-4 mb-0">{title} Download</h5>
        <Table striped bordered hover size="sm" responsive="sm">
          <thead className="thead-dark">
            <tr>
              <th className="fitContent">#</th>
              <th>App</th>
              <th className="fitContent">Traffic</th>
              <th className="fitContent"></th>
            </tr>
          </thead>
          <tbody>
            {topDownload?.map((item,i)=>(
            <tr key={i}>
              <td data-cell="#">{i+1}</td>
              <td data-cell="Name">{item.name}</td>
              <td data-cell="Traffic">{formatBytes(item.value)}</td>
              {/* <td><Button size="sm" className="btn_block" variant="danger" disabled>Block</Button></td> */}
              <BlockApp serviceName={service_name} appName={item.name} updateBlocked={updateBlocked} setUpdateBlocked={setUpdateBlocked}></BlockApp>
            </tr>
            ))}
          </tbody>
        </Table>
      </Col>
      <Col md={12} xl={6}>
        <h5 className="text-center mt-4 mb-0">{title} Upload</h5>
        <Table striped bordered hover size="sm">
          <thead className="thead-dark">
            <tr>
              <th className="fitContent">#</th>
              <th>App</th>
              <th className="fitContent">Traffic</th>
              <th className="fitContent"></th>
            </tr>
          </thead>
          <tbody>
            {topUpload?.map((item,i)=>(
            <tr key={i}>
              <td data-cell="#">{i+1}</td>
              <td data-cell="Name">{item.name}</td>
              <td data-cell="Traffic">{formatBytes(item.value)}</td>
              {/* <td><Button size="sm" className="btn_block" variant="danger" disabled>Block</Button></td> */}
              <BlockApp serviceName={service_name} appName={item.name} updateBlocked={updateBlocked} setUpdateBlocked={setUpdateBlocked}></BlockApp>
            </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    </Row>
    :''
  )
}

export default TopApps;