import React from 'react';
import ServiceBlock from './ServiceBlock';
import Form from 'react-bootstrap/Form';


function ServiceSearchList({ services, fetchTask}) {

  const [searchField, setSearchField] = React.useState("");

  const handleChange = e => {
    setSearchField(e.target.value);
  };

  const filteredServices = services.filter(
    service => {
      return (
        service['Account__r']
        .toLowerCase()
        .includes(searchField.toLowerCase()) ||
        service['Name']
        .toLowerCase()
        .includes(searchField.toLowerCase()) ||
        service['Name__c']
        .toLowerCase()
        .includes(searchField.toLowerCase()) ||
        service['Status__c']
        .toLowerCase()
        .includes(searchField.toLowerCase())
      );
    }
  );

  const filtered = filteredServices.map(service =>  <ServiceBlock {...service} key={service.Id} fetchTask={fetchTask}/>); 
    
  return (
    <>
    <div className="services__search d-flex justify-content-end">
      <Form.Control size="lg" type="search" placeholder="Filter" onChange = {handleChange} className="mb-3"/>
    </div>
      <div className="services__wrapper">
          {filtered}
      </div>
    </>

  );
}

export default ServiceSearchList;