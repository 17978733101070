import axios from 'axios'
import {createRefresh} from 'react-auth-kit'

const refreshToken = createRefresh({
  interval: 30,  
  refreshApiCallback: async (
    {
      authToken,
      authTokenExpireAt,
      refreshToken,
      refreshTokenExpiresAt,
      authUserState
    }) => {
    try {
      const options = {
        method: "POST",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${authToken}`,
        },
        url: "/api/auth/refresh-bearer",
      };

      const response = await axios(options)
      
      return {
        isSuccess: true,
        newAuthToken: response.data,
        newAuthTokenExpireIn: 60,
        newRefreshToken: response.data,
        newRefreshTokenExpiresIn: 60,
      }
    }
    catch(error){
      console.error(error)
      return {
        isSuccess: false
      } 
    }    
  }
})

export default refreshToken