import React from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Label,
    ResponsiveContainer,
    CartesianGrid,
    Tooltip,
    Legend
  } from 'recharts'


export const CustomTooltip = ({ active, payload, label, unit, toFixed=0}) => {
if (active && payload && payload.length) {
    function convertTimestampToDate(timestamp) {
        return new Date(timestamp * 1000).toLocaleTimeString("en-GB");
    }
    function convertB(bytes) {
        let newUnit = unit
        if(bytes > 1000000000){
            if (unit === 'bps') newUnit = `T${unit}`
            return (bytes/1000000000).toFixed(toFixed).toString() + ` ${newUnit}`;
        }else if(bytes > 1000000){
            if (unit === 'bps') newUnit = `M${unit}`
            return (bytes/1000000).toFixed(toFixed).toString() + ` ${newUnit}`;
        }else if(bytes > 1000){
            if (unit === 'bps') newUnit = `K${unit}`
            if (unit === 'ms') newUnit = 's' 
            return (bytes/1000).toFixed(toFixed).toString() + ` ${newUnit}`;
        }else{
            return bytes.toFixed(toFixed).toString(toFixed) + ` ${newUnit}`;
        }
    }
    return (
    
        <div className="custom-tooltip">
            {payload.map((data)=>(
                <div key={data['name']}>
                    <span style={{ color:`${data['stroke']}`}} className="value">{data['name']}: </span>
                    <span className="value">{`${convertB(data['value'])}`}</span>
                </div>
            ))}
            
            <div className="label">{`${convertTimestampToDate(label)}`}</div>
        </div>
    );
}

return null;
};

function LineChartTimeSeries({title, name, name2, data, data2, unit, toFixed=0}) {

    function convertTimestampToDate(timestamp) {
        return new Date(timestamp * 1000).toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit'});
    }
    function convertB(bytes) {
        let newUnit = unit
        if(bytes > 1000000000){
            if (unit === 'bps') newUnit = `T${unit}`
            return (bytes/1000000000).toFixed(toFixed).toString() + ` ${newUnit}`;
        }else if(bytes > 1000000){
            if (unit === 'bps') newUnit = `M${unit}`
            return (bytes/1000000).toFixed(toFixed).toString() + ` ${newUnit}`;
        }else if(bytes > 1000){
            if (unit === 'bps') newUnit = `K${unit}`
            if (unit === 'ms') newUnit = 's'
            return (bytes/1000).toFixed(toFixed).toString() + ` ${newUnit}`;
        }else{
            return bytes.toFixed(toFixed).toString(toFixed) + ` ${newUnit}`;
        }
    }

     
    return(
        <div>
            <h5 className="text-center mt-4 mb-0">{title}</h5>
            <ResponsiveContainer width={"100%"} height={400}>
                <LineChart data={data} margin={{ top: 30, right: 10, left: 0, bottom: 30 }}>
                    {(!data || data?.length === 0) && (!data2 || data2?.length === 0)?
                        <text x={"50%"} y={"45%"} fill="black" textAnchor="middle" dominantBaseline="central">
                            <tspan fontSize="24">No Data</tspan>
                        </text>:''}
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis 
                        dataKey="clock" 
                        tickFormatter={(timestamp) => convertTimestampToDate(timestamp)} 
                        ticks={data?.map(({ clock }) => clock)}
                        tick={{ fontSize: 14 }}
                        interval={30} 
                        scale="time" 
                        type="time"
                        reversed
                    />
                    <YAxis
                        tickFormatter={(bytes) => convertB(bytes,unit)}
                        tick={{ fontSize: 10 }}
                    >
                        <Label
                            position="left"
                            angle={-90}
                            style={{ textAnchor: "middle" }}
                            tickFormatter={(bytes) => convertB(bytes,unit)}
                        />
                    </YAxis>
                    <Tooltip content={<CustomTooltip unit={unit} toFixed={toFixed}/>} cursor={{ fill: "transparent" }} />
                    <Legend />
                    <Line
                        data={data}
                        name={name}
                        dataKey="value"
                        unit={unit}
                        dot={false}
                        type="monotone"
                        stroke="#084C61"
                    />
                    {data2?
                    (<Line
                        data={data2}
                        name={name2}
                        dataKey="value"
                        unit={unit}
                        dot={false}
                        type="monotone"
                        stroke="#DB504A"
                    />):
                    ''}
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default LineChartTimeSeries;