import React from 'react';
import Alert from 'react-bootstrap/Alert';

// alerts=[{severity:str, message:str}]
// setAlert = React.useState[{severity:str, message:str}]
function AlertList({alerts, setAlerts}) {

    const handleClose = (index) => {
        let new_alerts = [...alerts]
        delete new_alerts[index];
        setAlerts([...new_alerts])
    };

    return (
        <div className="alert_section">
            {alerts.map((alert, index) => {
                if (alert) {
                    return <Alert key={index} variant={alert.severity} onClose={() => handleClose(index)} dismissible className="alert_section_stick_br">
                            {alert.message}
                           </Alert>
                }
                return null
            })}
        </div>
    )
}

export default AlertList

