import Navbar from "../Navbar";
import React from "react";
import axios from "axios";
import { useAuthHeader } from "react-auth-kit";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import AlertList from "../Alert/Alert";
import Popover from 'react-bootstrap/Popover';
import Col from 'react-bootstrap/Col';
import Modal from "react-bootstrap/Modal";


function CustomerTechSuspension() {
  const authHeader = useAuthHeader();
  const [loading, setLoading] = React.useState(true);
  const [sfCustomers, setSfCustomers] = React.useState([]);
  const [selectedCustomer, setSelectedCustomer] = React.useState("");
  const [services, setServices] = React.useState([]);
  const [selectedServices, setSelectedServices] = React.useState([]);
  const [selectedStatus, setSelectedStatus] = React.useState("Commercially activated");
  const [show, setShow] = React.useState(false);
  const [showSubmit, setShowSubmit] = React.useState(true);
  const [modalHeading, setModalHeading] = React.useState("");
  const [modalBody, setModalBody] = React.useState("");
  const [alerts, setAlerts] = React.useState([]);

  const handleClose = () => {
    setShow(false);
    setShowSubmit(true);
  };

  const handleShow = () => {
    let head = ''
    if(selectedStatus === 'Commercially activated') {
      head = `Technical suspension`

    } else if(selectedStatus === 'Technical suspension') {
      head = `Activate`
    }
    setModalBody(
      <span>
        Click the "Submit" button to change the status of {selectedServices.length} services to "{head}"
      </span>
    );
    setModalHeading(head)
    setShow(true)
  };

  const handleGetService = (selectedCustomer) => {
    if (selectedCustomer !== 'Select Customer' && selectedCustomer){
      setAlerts([])
      fetchCustomerServices(selectedCustomer)
    } else {
      const newAlert = {severity:'danger', message:'Customer is not selected.'}
      setAlerts([...alerts, newAlert])
    }
  }


  const handleChangeStatus = (e) => {
    setSelectedServices([])
    setSelectedStatus(e)
  }

  const handleChangeCustomer = (e) => {
    setSelectedServices([])
    setServices([])
    setSelectedCustomer(e)
  }

  const handleServiceCheck = (e) => {
    if (!e.target.checked){
      let new_data = [...selectedServices]
      var index = new_data.indexOf(e.target.id);
      if (index > -1) {
        new_data.splice(index, 1);
      }
      setSelectedServices([...new_data])
      return;
    } else if(selectedServices.indexOf(e.target.id) === -1 && e.target.checked) {
      const data = [...selectedServices, e.target.id]
      setSelectedServices(data)
    }
  }

  const handleChangeServicesStatus = async () => {
    setShowSubmit(false)
    setModalBody(
      <div className="align-self-center">
        Waiting for a server response...
        <Spinner
          size="sm"
          animation="border"
          role="status"
          variant="primary"
          className="ms-2"
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
    let result = []
    let resultAlerts = []
    console.log(selectedServices)
    let url_end = ''
    if(selectedStatus === 'Commercially activated') {
      url_end = '/deactivate?is_technical=true'
    } else if(selectedStatus === 'Technical suspension') {
      url_end = '/activate'
    }
    for (const service of selectedServices) {
        const url = `api/service/${service}${url_end}`
        console.log(`Changing status for ${service}`)
        const options = {
          method: "POST",
          headers: {
              "content-type": "application/x-www-form-urlencoded",
              "Access-Control-Allow-Origin": "*",
              Authorization: authHeader(),
          },
          url: url,
        };
        await axios(options)
          .then((resp) => {
            result.push(resp)
        })
        .catch((error) => {
          const newAlert = {severity:'danger', message:`Failed to change status ${service}`}
          resultAlerts.push(newAlert)
        });
    }
    setAlerts([...alerts, ...resultAlerts])
    setModalBody(
      <span>
        {result.length} Tasks Created
      </span>
    );

  }

  const fetchCustomerServices = async (id) => {   
    setLoading(true)

    let url = `api/service/?customer_id=${id}&only_resellers=false`;
    const options = {
      method: "GET",
      headers: {
          "content-type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Origin": "*",
          Authorization: authHeader(),
      },
      url: url,
    };
    await axios(options)
    .then((resp) => {
      setServices(resp.data)
    })
    .catch((error) => {
      const newAlert = {severity:'danger', message:"Failed to load Customer services"}
      setAlerts([...alerts, newAlert])
    });
    setLoading(false)
  };

  React.useEffect(() => {
    const fetchCustomers = async () => {   
      setLoading(true)

      let url = `api/customer/`;
      const options = {
      method: "GET",
      headers: {
          "content-type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Origin": "*",
          Authorization: authHeader(),
      },
      url: url,
      };
      await axios(options)
      .then((resp) => resp.data)
      .then((data) => 
          data.sort((a, b) => {
              const nameA = a.name.toUpperCase(); // ignore upper and lowercase
              const nameB = b.name.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
              return -1;
              }
              if (nameA > nameB) {
              return 1;
              }
              return 0;
          })
      )
      .then((sort_data) => {
        setSfCustomers(sort_data)
      })
      .catch((error) => {
        const newAlert = {severity:'danger', message:"Failed to load Customers list"}
        setAlerts(a => [...a, newAlert])
      });
    };

    fetchCustomers();

    setLoading(false)

  }, []);

  return (
    <>
      <Navbar />
      <section className="tech-suspension justify-content-center">
        <Container fluid="sm">
          <h1 className="text-center mt-4">Technical Suspension</h1>
              <Row className="m-auto mb-5 mt-4">
                <Col className="px-0" xs={12} sm={6} md={6} lg={7}>
                  <Form.Select
                    className="tech-suspension_col_left"
                    aria-label="Select Customer"
                    onChange={e => {handleChangeCustomer(e.target.value);}}
                  >
                    <option>Select Customer</option>
                    {sfCustomers.map(customer => {
                      return <option key={customer.id} value={customer.id}>{customer.name}</option>
                    })}
                  </Form.Select>
                </Col>
                <Col className="px-0" xs={12} sm={6} md={4} lg={3}>
                  <Form.Select 
                    className="tech-suspension_col_middle"
                    aria-label="Select Service Status"
                    onChange={e => {handleChangeStatus(e.target.value);}}
                  >
                    <option value="Commercially activated">Commercially activated</option>
                    <option value="Technical suspension">Technical suspension</option>
                  </Form.Select>
                </Col>
                <Col className="px-0" xs={12} sm={12} md={2} lg={2}>
                  <Button className="tech-suspension_col_right w-100" variant="primary" onClick={() => {handleGetService(selectedCustomer)}}>
                    Get Services
                  </Button>
                </Col>
              </Row>
            {loading?(
              <Spinner animation="border" role="status" variant="primary" className="mx-auto d-block">
                <span className="visually-hidden">Loading...</span>
              </Spinner> 
            ):
            <>
            {services.map((service) => {
              if (service.Status__c === selectedStatus)
                return (
                  <Row className="m-auto mb-3" key={service.Id}>
                    <Col className="px-0" xs={12} sm={12} md={6} lg={7}>
                      <InputGroup className="">
                        <InputGroup.Checkbox
                          className="tech-suspension_service_chk_col"
                          id={service.Id} 
                          name='service'
                          onChange={(e) => handleServiceCheck(e)}
                        />
                        <Form.Control 
                          className="tech-suspension_service_name_col"
                          readOnly 
                          defaultValue={service.Name}
                        />
                      </InputGroup>
                    </Col>
                    <Col className="px-0" xs={6} sm={6} md={4} lg={3}>
                      <InputGroup.Text 
                        className={`tech-suspension_service_status_col ${service.Status__c === "Commercially activated"?"commerce_activated":"tech_suspension"}`}
                        readOnly 
                        defaultValue={service.Status__c}>
                          {service.Status__c}
                      </InputGroup.Text>
                    </Col>
                    <Col className="px-0" xs={6} sm={6} md={2} lg={2}>
                        <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            rootClose
                            overlay={
                                <Popover id={`popover-positioned-bottom`}>
                                  <Popover.Header as="h3">{service.Name} Remotes</Popover.Header>
                                  <Popover.Body>
                                  <div className="services__wrapper_item_remote_data">
                                    {service.Remotes.map((remote) => (
                                    <div className="remote" key={remote['Satellite__c']+remote['DID__c']}>
                                        <div className="remote_name">{remote['Name']}</div>       
                                        <div className="remote_satellite">{remote['Satellite__c']}</div>
                                        <hr />
                                    </div>
                                    
                                    ))}
                                    {service.Remotes.length === 0? <p className="text-center">Not found📡</p>:""}
                                  </div>
                                  </Popover.Body>
                                </Popover>
                              }>
                              <Button className="w-100 tech-suspension_service_btn_col" variant="primary">
                                Remotes
                              </Button>
                        </OverlayTrigger>
                      </Col>
                  </Row>
                )
                return null
              }
            )}
            {selectedServices.length > 0?
              <Row className="m-auto mt-5">
                <Col className="px-0" xs={12} sm={12} md={3} lg={3}>
                  {selectedStatus === 'Commercially activated'?
                  <Button className='tech_suspension w-100' onClick={handleShow}>Technically suspend</Button>
                  :
                  <Button className='commerce_activated w-100' onClick={handleShow}>Activate</Button>}
                </Col>
              </Row>
            :null}
            </>
            }
        </Container>
        <AlertList alerts={alerts} setAlerts={setAlerts}></AlertList>
      </section>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {modalHeading}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
          {showSubmit ? (
            <Button onClick={handleChangeServicesStatus}>
              Submit
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CustomerTechSuspension;
