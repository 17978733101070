import Navbar from "../Navbar";
import React from "react";
import Container from "react-bootstrap/esm/Container";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import BlockedApps from "../RemoteStatistics/BlockedApps";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AlertList from "../Alert/Alert";

function FWBlock() {
    const [serviceName, setServiceName] = React.useState('')
    const [network, setNetwork] = React.useState('')
    const [alerts, setAlerts] = React.useState([]);
    const [showBlocks, setShowBlocks] = React.useState(false)
    const [blocks, setBlocks] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const showBlocked = async ()=>{
        setAlerts([])
        
        if ( network.length === 0 && serviceName.length  === 0 ) {

            const newAlert = {severity:'danger', message:'Enter Network or Service Name'}
            setAlerts(a => [...a, newAlert])
            return;
        }
        if ( network.length !== 0 && serviceName.length  !== 0 ) {
            const newAlert = {severity:'danger', message:'Enter either Network or Service Name'}
            setAlerts(a => [...a, newAlert])
            return;
        }
        if ( serviceName.length  !== 0 ) {
            if (!serviceName.match(/^SRV-\d{6}$/)) {
                const newAlert = {severity:'danger', message:'Wrong Service Name format (SRV-XXXXXX)'}
                setAlerts(a => [...a, newAlert])
                return;
            }
        }
        if ( network.length  !== 0 ) {
            if (!network.match(/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}\/([0-9]|[1-2][0-9]|3[0-2])$/)) {
                const newAlert = {severity:'danger', message:'Wrong Network format(192.168.0.1/24)'}
                setAlerts(a => [...a, newAlert])
                return;
            }
        }
        setLoading(true)
        let blocked = <BlockedApps network={network} service_name={serviceName} return_empty={true}/>
        setBlocks(blocked)
        setLoading(false)
        setShowBlocks(true)
      };

      
    const handleNameChange = (e) => {
        setShowBlocks(false)
        e.preventDefault();
        setNetwork('');
        setServiceName(e.target.value);
    };
    const handleNetChange = (e) => {
        setShowBlocks(false)
        e.preventDefault();
        setServiceName('');
        setNetwork(e.target.value);
        };

    return(
    <>
    <Navbar/>
    <Container fluid="sm">
        <h1 className="text-center mt-4">Check Blocking Rules</h1>
        <Row className="m-auto">
            <Form>    
                <Col md={12} xl={6}> 
                    <Form.Group className="mb-3" controlId="fwBlock.controlName">
                        <Form.Label>Service Name</Form.Label>
                        <Form.Control type="string" placeholder="SRV-000001" value={serviceName} onChange = {handleNameChange}/>
                    </Form.Group>
                </Col>
                <Col md={12} xl={6} className="text-center">or</Col>
                <Col md={12} xl={6}> 
                    <Form.Group className="mb-3" controlId="fwBlock.controlName">
                        <Form.Label>Network</Form.Label>
                        <Form.Control type="string" placeholder="192.168.0.0/29" value={network} onChange={handleNetChange}/>
                    </Form.Group>
                </Col>
                <Col md={12} xl={6} className="mt-2"> 
                {loading?
                    <Button variant="primary" disabled>Loading...</Button>
                :
                    <Button variant="primary" onClick={showBlocked}>Check</Button>
                }
                    
                </Col>
            </Form>
        </Row>
        {showBlocks?blocks:null}
        <AlertList alerts={alerts} setAlerts={setAlerts}></AlertList>
    </Container>
    </>
    );
}

export default FWBlock;