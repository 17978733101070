import React from "react";
import TaskRevoke from "./TaskRevoke";

function TaskItem({task_status, task_user, task_service, task_result, task_id}){
    const [classTask, setClassTask] = React.useState('task__wrapper_item task__wrapper_item_process')
    const [statusTask, setStatusTask] = React.useState('Status: Process')
    const [userTask, setUserTask] = React.useState('Unknown')
    const [resultTask, setResultTask] = React.useState('...')
    const [progressTask, setProgressTask] = React.useState('')

    React.useEffect(() => {
        let classTask = 'task__wrapper_item ';
        let status = '';
        let result = '';
        let progress = '';
        if (task_status === 'PROGRESS_STATE') {
            classTask += 'task__wrapper_item_process';
            status += 'Process';
            progress += `Stage ${task_result.current} of ${task_result.total}`;
            result += task_result.message;
        } else if (task_status === 'SUCCESS') {
            classTask += 'task__wrapper_item_success';
            status += 'Success';
            result += task_result;
        } else if (task_status === 'FAILURE') {
            classTask += 'task__wrapper_item_failure';
            status += 'Failure';
            result += 'Something went wrong';
        } else if (task_status === 'RETRY') {
            classTask += 'task__wrapper_item_retry';
            status += 'Retry';
            result += task_result.message;
        } else if (task_status === 'REVOKED') {
            classTask += 'task__wrapper_item_revoked';
            status += 'REVOKED';
            result += 'Revoked';
        }
        setResultTask(result)
        setStatusTask(status)
        setClassTask(classTask)
        setProgressTask(progress)
        setUserTask(task_user)

    }, [task_result, task_status, task_user]);

    return (       
        <div className={classTask}>
            <div className="task__wrapper_item_user">User: {userTask}</div>
            <div className="task__wrapper_item_service-name">Service: {task_service}</div>
            <div className="task__wrapper_item_head">
                <div className="task__wrapper_item_head_status">Status: {statusTask}</div>
                <div className="task__wrapper_item_head_progress">{progressTask}</div>
            </div>
            <div className="task__wrapper_item_result">{resultTask}</div>
            <TaskRevoke task_id={task_id} task_status={task_status}></TaskRevoke>
        </div>
    )
}

export default TaskItem;