import Login from './components/Login/Login';
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { AuthProvider, useIsAuthenticated } from 'react-auth-kit'
import Services from './components/Services/Services';
import CustomerTechSuspension from './components/Services/CustomerTechSuspension';
import ServicesLogs from './components/Services/ServicesLogs';
import Home from './components/Home';
import Tasks from './components/Tasks/Tasks';
import User from './components/Settings/User';
import ModCodes from './components/Settings/ModCodes';
import refreshToken from "./components/Login/refreshToken";
import NotFound404 from "./components/NotFound404";
import RemoteStatistics from './components/RemoteStatistics/RemoteStatistics';
import Block from './components/FW/Block';
import NewServiceAndRemote from './components/Services/NewServiceAndRemote';

function App() {
  const PrivateRoute = ({ Component }) => {
    const isAuthenticated = useIsAuthenticated();
    const auth = isAuthenticated();
    return auth ? <Component /> : <Navigate to="/login" />;
  };

  const CheckLoggedIn = () => {
    const isAuthenticated = useIsAuthenticated();
    const auth = isAuthenticated();

    return auth ? <Navigate to="/"/> : <Login />;
  }

  return (
    <AuthProvider authType = {'cookie'}
    authName={'axess_portal'}
    cookieDomain={window.location.hostname}
    cookieSecure={window.location.protocol === "https:"}
    refresh={refreshToken}>
      <BrowserRouter>
        <Routes>
          <Route path={'/login' } element={<CheckLoggedIn />}/>
          <Route path={'/services'} element={<PrivateRoute Component={Services} />}/>
          <Route path={'/services-logs'} element={<PrivateRoute Component={ServicesLogs} />}/>
          <Route path={'/pre-activation-survey'} element={<PrivateRoute Component={NewServiceAndRemote} />}/>
          <Route path={'/tasks'} element={<PrivateRoute Component={Tasks} />}/>
          <Route path={'/'} element={<PrivateRoute Component={Home} />}/>
          <Route path={'/user'} element={<PrivateRoute Component={User} />}/>
          <Route path={'/modcodes'} element={<PrivateRoute Component={ModCodes} />}/>
          <Route path={'/statistics'} element={<PrivateRoute Component={RemoteStatistics} />}/>
          <Route path={'/fw-block'} element={<PrivateRoute Component={Block} />}/>
          <Route path={'/tech-suspension'} element={<PrivateRoute Component={CustomerTechSuspension} />}/>
          <Route path="/404" element={<PrivateRoute Component={NotFound404} />}/>
          <Route path='*' element={<Navigate to="/404" />}/>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
