import React from "react";

function ModalTaskItem({task_status, task_service, task_result, task_id, handleDeleteTask}){
    const [classTask, setClassTask] = React.useState('modal_task_main modal_task_main_process')
    const [statusTask, setStatusTask] = React.useState('Status: Process')
    const [resultTask, setResultTask] = React.useState('...')
    const [progressTask, setProgressTask] = React.useState('')

    const hideTask = () => {
        handleDeleteTask(task_id);
    };

    React.useEffect(() => {
        let classTask = 'modal_task_main ';
        let status = '';
        let result = '';
        let progress = '';
        if (task_status === 'PROGRESS_STATE') {
            classTask += 'modal_task_main_process';
            status += 'Status: Process';
            progress += `Stage ${task_result.current} of ${task_result.total}`;
            result += task_result.message;
        } else if (task_status === 'SUCCESS') {
            classTask += 'modal_task_main_success';
            status += 'Status: Success';
            result += task_result;
        } else if (task_status === 'FAILURE') {
            classTask += 'modal_task_main_failure';
            status += 'Status: Failure';
            result += 'Something went wrong';
        } else if (task_status === 'RETRY') {
            classTask += 'modal_task_main_retry';
            status += 'Status: Retry';
            result += 'Please wait, the task will be executed shortly';
        } else if (task_status === 'REVOKED') {
            classTask += 'modal_task_main_revoked';
            status += 'Status: Revoked';
            result += 'Task revoked';
        }
        setResultTask(result)
        setStatusTask(status)
        setClassTask(classTask)
        setProgressTask(progress)

    }, [task_result, task_status]);

    return (       
        <div className={classTask}>
            <span className="modal_task_main_close" onClick={hideTask}>
            </span>
            <div className="modal_task_main_service-name">{task_service}</div>
            <div className="modal_task_main_head">
                <div className="modal_task_main_head_status">{statusTask}</div>
                <div className="modal_task_main_head_progress">{progressTask}</div>
            </div>
            <div className="modal_task_main_result">{resultTask}</div>
        </div>
    )
}

export default ModalTaskItem;